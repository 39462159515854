import React from 'react';
import Select, {Theme} from 'react-select';

import {OptionType} from 'types';


interface CustomSelectProps{
    options: OptionType[],
    customStyle?: React.CSSProperties,
    singleValueStyle?: React.CSSProperties
}

const CustomSelect: React.FC<CustomSelectProps> = (props) => {

    const customStyles = {
        control: (provided: React.CSSProperties) => ({
            ...provided,
            height: '100%',
            minHeight: 36,
            borderRadius: 1,
            border: '1px solid #e5e6e7',
            fontSize: '0.9rem',
            ...props.customStyle,
        }),
        singleValue: (provided: React.CSSProperties) => ({
            ...provided,
            ...props.singleValueStyle,
        }),
    };

    return (
        <Select
            {...props}
            options={props.options}
            // @ts-ignore
            styles={customStyles}
            theme={(theme: Theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: '#1ab3941a',
                    primary50: '#1ab39454',
                    primary: '#1ab394',
                },
            })}
        />
    );
};


export default React.memo(CustomSelect);