import React from 'react';

import {useParams} from 'react-router';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import {accessAPI} from '../api/accessAPI';
import {Pages} from '../constants/pages';
import Cloud from '../components/Clouds/Cloud';
import NotFound from './NotFound';

const CloudPage: React.FC = () => {

    const {cloudId} = useParams();

    if (!cloudId) {
        return <NotFound/>;
    }

    const {data: item, isLoading} = accessAPI.useGetAccessQuery(Number(cloudId));
    return (
        <MainLayout pageTitle={Pages.ACCESSES} isLoading={isLoading}>
            {
                item && <Cloud item={item}/>
            }
        </MainLayout>
    );
};

export default CloudPage;