import React from 'react';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import Clouds from '../components/Clouds/Clouds';
import {accessAPI} from '../api/accessAPI';
import {Pages} from '../constants/pages';

const CloudsPage: React.FC = () => {
    const {data, isLoading} = accessAPI.useGetAccessesQuery();
    return (
        <MainLayout pageTitle={Pages.ACCESSES} isLoading={isLoading}>
            <Clouds items={data ?? []}/>
        </MainLayout>
    );
};

export default CloudsPage;