// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".withSpinner_spinner-overlay__VE5m4 {\n  display: none;\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(255, 255, 255, 0.9);\n  z-index: 999999;\n}\n.withSpinner_spinner-overlay--show__UMA51 {\n  display: flex;\n}", "",{"version":3,"sources":["webpack://./src/hoc/withSpinner.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;EACA,0CAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":[".spinner-overlay {\n  display: none;\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(255, 255, 255, 0.9);\n  z-index: 999999;\n\n  &--show {\n    display: flex;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner-overlay": "withSpinner_spinner-overlay__VE5m4",
	"spinner-overlay--show": "withSpinner_spinner-overlay--show__UMA51"
};
export default ___CSS_LOADER_EXPORT___;
