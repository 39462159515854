import React, {useState} from 'react';

import PrimaryButton from 'components/UI/Button/PrimaryButton';

import CreateAccessLinkModal from './CreateAccessLinkModal';

interface ICreateAccessLinkButtonProps {
    instanceId: number,
    accessId: number,
}

const CreateAccessLinkButton: React.FC<ICreateAccessLinkButtonProps> = ({
    instanceId,
    accessId,
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <PrimaryButton
                onClick={() => setIsModalOpen(true)}
            >
                Предоставить доступ
            </PrimaryButton>
            <CreateAccessLinkModal
                instanceId={instanceId}
                accessId={accessId}
                open={isModalOpen}
                handleClose={handleClose}
            />
        </>
    );
};

export default CreateAccessLinkButton;