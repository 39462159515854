import {configureStore} from '@reduxjs/toolkit';

import rootReducer from 'redux/reducers';

import {accessAPI} from './api/accessAPI';
import {instanceAPI} from './api/instanceAPI';
import {summaryAPI} from './api/summaryAPI';
import {accessLinkAPI} from './api/accessLinkAPI';
import mercureMiddleware from './redux/middleware/mercureMiddleware';


export const makeStore = (preloadedState: object | null = null) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(accessAPI.middleware)
                .concat(instanceAPI.middleware)
                .concat(summaryAPI.middleware)
                .concat(accessLinkAPI.middleware)
                .concat(mercureMiddleware),
        ...preloadedState ? {preloadedState} : {},
    });
};

export type RootState = ReturnType<typeof rootReducer>;