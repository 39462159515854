import React from 'react';

import {useSelector} from 'react-redux';

import IInstance from '../../../../models/Instance/IInstance';

import AccessLink from './AccessLink';

import {userSelector} from '../../../../redux/authSlice';

import CreateAccessLinkButton from './CreateAccessLinkButton';

import styles from './AccessLinks.module.scss';

interface IAccessLinksProps {
    item: IInstance,
    accessId: number,
}

const AccessLinks: React.FC<IAccessLinksProps> = ({item, accessId}) => {
    const user = useSelector(userSelector);
    
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>Доступы</div>
            <p className={styles.description}>
                Вы можете предоставить доступ по ссылке для принудительного включения и выключения
                этой виртуальной машины. Этот доступ не распространяется на изменение расписания.
            </p>
            <div>
                <table className="table">
                    <tbody>
                        {
                            item.access_links.map((accessLink) => (
                                <AccessLink
                                    key={accessLink.uuid}
                                    item={accessLink}
                                    instanceId={item.id}
                                    timezone={user?.timezone}
                                />
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start my-2">
                <CreateAccessLinkButton accessId={accessId} instanceId={item.id}/>
            </div>
        </div>
    );
};

export default AccessLinks;