import React from 'react';

import {isEmpty} from 'lodash';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import {Pages} from '../constants/pages';
import {summaryAPI} from '../api/summaryAPI';
import HomeBlank from '../components/Home/HomeBlank/HomeBlank';
import HomeSummary from '../components/Home/HomeSummary';


const HomePage: React.FC = () => {
    const {data: items, isLoading} = summaryAPI.useGetSummaryItemsQuery();
    return <MainLayout pageTitle={Pages.HOME} isLoading={isLoading}>
        {
            !isLoading && isEmpty(items) && <HomeBlank/>
        }

        {
            !isLoading && items && !isEmpty(items) && <HomeSummary items={items}/>
        }
    </MainLayout>;
};

export default HomePage;