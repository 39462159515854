import React from 'react';

import {faTimes} from '@fortawesome/free-solid-svg-icons';

import IconButton, {IIconButtonProps} from './IconButton';

type ICancelButtonProps = Omit<IIconButtonProps, 'icon'>;

const CancelButton: React.FC<ICancelButtonProps> = (props) => {

    return (
        <IconButton icon={faTimes} {...props}/>
    );
};

export default CancelButton;