// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cloude_image_wrapper__c\\+q4e {\n  margin-right: 1rem;\n}\n\n.Cloude_buttons__f5QkL {\n  display: flex;\n}\n\n@media only screen and (max-width: 768px) {\n  .Cloude_image_wrapper__c\\+q4e {\n    display: none;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Clouds/Cloude.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACA;EACI,aAAA;AAEJ;;AACA;EACI;IACI,aAAA;EAEN;AACF","sourcesContent":[".image_wrapper {\n    margin-right: 1rem;\n}\n.buttons{\n    display: flex;\n}\n\n@media only screen and (max-width: 768px) {\n    .image_wrapper {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image_wrapper": "Cloude_image_wrapper__c+q4e",
	"buttons": "Cloude_buttons__f5QkL"
};
export default ___CSS_LOADER_EXPORT___;
