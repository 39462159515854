import React from 'react';

import {useSelector} from 'react-redux';

import BlankLayout from '../../components/Layout/BlankLayout/BlankLayout';
import {isPasswordResettingSelector} from '../../redux/authSlice';
import PasswordResetInit from '../../components/Auth/Password/PasswordResetInit';


const PasswordResetInitPage: React.FC = () => {
    const isLoading = useSelector(isPasswordResettingSelector);
    return (
        <BlankLayout pageTitle="Сброс пароля" isLoading={isLoading}>
            <PasswordResetInit/>
        </BlankLayout>
    );
};

export default PasswordResetInitPage;