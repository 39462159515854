import React from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';
import cn from 'classnames';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';

import Box from '../../UI/Box/Box';
import FormGroup from '../../UI/FormGroup/FormGroup';
import EmailInput from '../../UI/Input/EmailInput';
import Alert from '../../UI/Alert/Alert';
import PrimaryButton from '../../UI/Button/PrimaryButton';
import {AppUrls} from '../../../constants/urls';


import {TypedDispatch} from '../../../types';
import {passwordResetRequest} from '../../../redux/authSlice';
import IPasswordResetRequestForm from '../../../models/forms/IPasswordResetRequestForm';


import styles from '../Auth.module.scss';

const PasswordResetInit: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();

    const {register, handleSubmit, formState, getValues} = useForm<IPasswordResetRequestForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    const onSubmit: SubmitHandler<IPasswordResetRequestForm> = (formData) => {
        dispatch(passwordResetRequest(formData.email))
            .then(resultAction => {
                const response = unwrapResult(resultAction);
                if (response.status) {
                    navigate(`${AppUrls.PASSWORD_RESET_SENT}?email=${getValues('email')}`);
                }
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <Box>
                    <div className="row text-center mb-3">
                        <div className="col-12">
                            <h2>Восстановление доступа</h2>
                            <p>Введите адрес email вашего аккаунта и мы пришлем на него ссылку для сброса пароля.</p>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="reset-password-form">

                        <FormGroup hasError={errors.email !== undefined}>
                            <EmailInput
                                register={register}
                                required
                            />
                            {errors.email && <Alert>{errors.email.message}</Alert>}
                        </FormGroup>

                        <FormGroup className="form-group">
                            <PrimaryButton
                                type="submit"
                                className="full-width"
                                disabled={!isDirty || !isValid}
                            >Сбросить пароль</PrimaryButton>
                        </FormGroup>
                    </form>

                    <p className={cn(styles.new_account_wrapper, 'text-muted')}>
                        Вспомнили? <Link to={AppUrls.LOGIN}>Войти!</Link>
                    </p>
                </Box>
            </div>
        </div>
    );
};

export default PasswordResetInit;