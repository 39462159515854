// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisappearedOverlay_wrapper__fgbA- {\n  position: relative;\n  padding: 2em;\n}\n\n.DisappearedOverlay_overlay__HxwGj {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.1);\n}", "",{"version":3,"sources":["webpack://./src/components/Instances/Instance/DisappearedOverlay/DisappearedOverlay.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;AACJ","sourcesContent":[".wrapper{\n    position: relative;\n    padding: 2em;\n}\n\n.overlay{\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0,0,0,0.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "DisappearedOverlay_wrapper__fgbA-",
	"overlay": "DisappearedOverlay_overlay__HxwGj"
};
export default ___CSS_LOADER_EXPORT___;
