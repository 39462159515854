import React, {MouseEvent, useCallback} from 'react';
import {toast} from 'react-toastify';
import {Button} from 'rsuite';
import {To, useNavigate} from 'react-router-dom';

import IInstance from 'models/Instance/IInstance';
import PrimaryButton from 'components/UI/Button/PrimaryButton';
import {NormalizedStatus} from 'models/Instance/NormalizedStatus';
import {ManagementStatus} from 'models/Instance/ManagementStatus';
import {instanceAPI} from 'api/instanceAPI';

import StatusBadge from '../../../Status/StatusBadge';

import styles from './InstanceHeader.module.scss';


interface IInstanceHeaderProps {
    item: IInstance,
    cloudUrl?: To,
    onClickOn: ()=>void,
    onClickOff: ()=>void,
    onClickRestart: ()=>void,
    isAnon: boolean,
}

const InstanceHeader: React.FC<IInstanceHeaderProps> = ({
    item,
    cloudUrl,
    onClickOn,
    onClickOff,
    onClickRestart,
    isAnon,
}) => {

    const isDisappeared = item.management_status === ManagementStatus.Disappeared;
    const [updateInstance, {isLoading}] = instanceAPI.useUpdateInstanceMutation();
    const navigate = useNavigate();
    const onClickForget = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();

        await updateInstance({
            id: item.id,
            management_status: ManagementStatus.Deleted,
        }).unwrap();

        toast.info('Машина забыта');
        cloudUrl && navigate(cloudUrl);
    }, []);

    return (
        <div className={styles.row}>
            <div className={styles.left_col}>
                <div className={styles.name}>{item.name}</div>
                <StatusBadge
                    status={item.status}
                    normalizedStatus={item.normalized_status}
                    managementStatus={item.management_status}
                />
            </div>
            {
                isDisappeared
                    ? <div>
                        {
                            !isAnon && <Button
                                loading={isLoading}
                                onClick={onClickForget}
                            >Забыть
                            </Button>
                        }
                    </div>
                    : <div>
                        {
                            item.normalized_status === NormalizedStatus.Off
                            && <PrimaryButton onClick={onClickOn}>Включить</PrimaryButton>
                        }
                        {
                            item.normalized_status === NormalizedStatus.On
                            && <PrimaryButton
                                className="mx-2"
                                onClick={onClickOff}
                            >
                                Выключить
                            </PrimaryButton>
                        }
                        {
                            item.normalized_status === NormalizedStatus.On
                            && <PrimaryButton
                                onClick={onClickRestart}
                            >
                                Перезагрузить
                            </PrimaryButton>
                        }
                    </div>
            }
        </div>
    );
};

export default InstanceHeader;