import React, {ChangeEvent, useState} from 'react';

import Input, {InputProps} from './Input';

const GrowingInput: React.FC<InputProps> = ({onInput, ...rest}) => {
    const calcInputLength = (value: string) => (value.length +5) * 8 + 'px';

    const [width, setWidth] = useState(calcInputLength(String(rest.defaultValue)));

    const onInputInput = (event: ChangeEvent<HTMLInputElement>) => {
        setWidth(calcInputLength(event.target.value));
        onInput && onInput(event);
    };

    return (
        <>
            <Input
                type="text"
                style={{ minWidth: width }}
                autoFocus
                {...rest}
                onInput={onInputInput}/>
        </>
    );
};

export default GrowingInput;