import React from 'react';

import ITimeRange from '../../../models/ITimeRange';

import TimeRangeControl from './TimeRangeControl';

import styles from './TimeRanges.module.scss';


interface ITimeRangesProps {
    ranges: ITimeRange[],
    backgroundColor: string,
    tzString?: string|null,
}

const TimeRanges: React.FC<ITimeRangesProps> = ({ranges, backgroundColor, tzString}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.day_range} style={{backgroundColor}}>
                {ranges.map((range) => (
                    <TimeRangeControl
                        key={range.timeFrom}
                        range={range}
                        tzString={tzString}
                    />
                ))}
            </div>
        </div>
    );
};

export default TimeRanges;