import React, {Dispatch, ReactNode, SetStateAction} from 'react';
import {Checkbox as PrettyCheckbox} from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox/dist/pretty-checkbox.css';

import styles from './Checkbox.module.scss';

export interface CheckboxProps {
    id: string | number,
    name?: string,
    onChange?: React.ChangeEventHandler<HTMLInputElement>,
    onBlur?: React.ChangeEventHandler<HTMLInputElement>,
    required?: boolean,
    checked?: boolean,
    children?: ReactNode,
    value?: string,
    state?: string | boolean | any[],
    setState?: Dispatch<SetStateAction<string | boolean | any[]>>
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    const Component: React.FC<CheckboxProps> = ({
        id,
        name,
        onChange,
        onBlur,
        required,
        checked,
        children,
        value,
        state,
        setState,
    }) => ((
        <label className={styles.checkbox_label}>
            <PrettyCheckbox
                ref={ref}
                // @ts-ignore
                id={id}
                name={name}
                className="p-inspinia"
                onChange={onChange}
                onBlur={onBlur}
                required={required}
                style={{zIndex: 0}}
                checked={checked}
                value={value}
                state={state}
                // @ts-ignore
                setState={setState}
            />
            <div className={styles.checkbox_text}>
                {children}
            </div>
        </label>
    ));

    return <Component {...props} />;
});

Checkbox.displayName = 'Checkbox';

export default React.memo(Checkbox);