// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EconomyCharts_wrapper__A03os {\n  display: flex;\n  justify-content: space-between;\n}\n\n.EconomyCharts_wrapper_single__OW0UL {\n  display: flex;\n  justify-content: flex-start;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/EconomyCharts/EconomyCharts.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;;AACA;EACI,aAAA;EACA,2BAAA;AAEJ","sourcesContent":[".wrapper{\n    display: flex;\n    justify-content: space-between;\n}\n.wrapper_single{\n    display: flex;\n    justify-content: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "EconomyCharts_wrapper__A03os",
	"wrapper_single": "EconomyCharts_wrapper_single__OW0UL"
};
export default ___CSS_LOADER_EXPORT___;
