import React from 'react';
import {useImage} from 'react-image';

declare const VALID_LOADING_VALUES: readonly ['lazy', 'eager', undefined];
declare type LoadingValue = typeof VALID_LOADING_VALUES[number];
declare type ImgElementStyle = NonNullable<JSX.IntrinsicElements['img']['style']>;

export interface StaticImageData {
    src: string;
    height: number;
    width: number;
}

export interface IImageProps {
    src: string | StaticImageData;
    width?: number | string;
    height?: number | string;
    style?: ImgElementStyle;
    alt: string;
    title?: string;
    loading?: LoadingValue;
    className?: string;
    objectFit?: ImgElementStyle['objectFit'];
}

const CustomImage: React.FC<IImageProps> = ({src, alt, loading, objectFit, style, ...rest}) => {
    const {src: srcData} = useImage({
        srcList: typeof src === 'string' ? src : src.src,
    });
    let newStyle = style;
    if (objectFit) {
        newStyle = {
            ...newStyle, ...{
                objectFit,
                maxWidth: '100%',
                maxHeight: '100%',
                minHeight: '100%',
                minWidth: '100%',
            },
        };
    }

    return <img
        {...rest}
        src={srcData}
        alt={alt}
        loading={loading ?? 'lazy'}
        style={newStyle}
    />;
};

export default CustomImage;