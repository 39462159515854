import React, {useCallback, useEffect} from 'react';

import {toast} from 'react-toastify';

import {useDispatch} from 'react-redux';

import Box from '../../../UI/Box/Box';
import InstanceHeader from '../InstanceHeader/InstanceHeader';
import SchedulesOverview from '../Schedules/SchedulesOverview';
import {ManagementStatus} from '../../../../models/Instance/ManagementStatus';
import DisappearedOverlay from '../DisappearedOverlay/DisappearedOverlay';
import IInstanceAnon from '../../../../models/Instance/IInstanceAnon';

import {accessLinkAPI} from '../../../../api/accessLinkAPI';

import {getMercureTokenAnon} from '../../../../redux/authSlice';
import {TypedDispatch} from '../../../../types';

import styles from './InstanceAnon.module.scss';

interface IInstanceProps {
    anonItem: IInstanceAnon,
}

const Instance: React.FC<IInstanceProps> = ({anonItem}) => {
    const dispatch = useDispatch<TypedDispatch>();
    useEffect(() => {
        dispatch(getMercureTokenAnon(anonItem.uuid));
    }, []);

    const item = anonItem.instance;
    const [changeStatus] = accessLinkAPI.useChangeInstanceStatusAnonMutation();

    const isDisappeared = item.management_status === ManagementStatus.Disappeared;

    const onClickOn = useCallback(async() => {
        await changeStatus({
            uuid: anonItem.uuid,
            targetStatus: 'on',
        }).unwrap();
        toast.info('Отправлен запрос на включение ВМ');
    }, [anonItem.uuid]);

    const onClickOff = useCallback(async() => {
        await changeStatus({
            uuid: anonItem.uuid,
            targetStatus: 'off',
        }).unwrap();

        toast.info('Отправлен запрос на выключение ВМ');
    }, [anonItem.uuid]);

    const onClickRestart = useCallback(async() => {
        await changeStatus({
            uuid: anonItem.uuid,
            targetStatus: 'restart',
        }).unwrap();

        toast.info('Отправлен запрос на перезапуск ВМ');
    }, [anonItem.uuid]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <Box>
                    <div className="mb-3">
                        <InstanceHeader
                            item={item}
                            onClickOn={onClickOn}
                            onClickOff={onClickOff}
                            onClickRestart={onClickRestart}
                            isAnon={true}
                        />
                    </div>
                    <DisappearedOverlay isDisappeared={isDisappeared}>
                        <SchedulesOverview
                            items={item.schedules_by_days}
                            turnedOnHours={item.turned_on_hours}
                            economy={item.economy_average}
                        />
                    </DisappearedOverlay>
                </Box>
            </div>
        </div>
    );
};

export default Instance;