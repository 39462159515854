// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeRanges_wrapper__iavaS {\n  padding: 25px 5px 5px;\n  width: 100%;\n}\n\n.TimeRanges_day_range__rxFF\\+ {\n  position: relative;\n  height: 10px;\n  width: 100%;\n}\n\n.TimeRanges_range__mQ40Y {\n  position: absolute;\n  height: 10px;\n  top: 0;\n}\n\n.TimeRanges_label_from__jPloc {\n  position: absolute;\n  left: 0;\n  top: -20px;\n  transform: translateX(-50%);\n}\n\n.TimeRanges_label_to__Q9Vxh {\n  position: absolute;\n  right: 0;\n  top: -20px;\n  transform: translateX(50%);\n}\n.TimeRanges_label_to__Q9Vxh.TimeRanges_down__klOyR {\n  top: 9px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/TimeRanges/TimeRanges.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,WAAA;AACJ;;AACA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;AAEJ;;AACA;EACI,kBAAA;EACA,YAAA;EACA,MAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,OAAA;EACA,UAAA;EACA,2BAAA;AAGJ;;AADA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,0BAAA;AAIJ;AAFI;EACI,QAAA;AAIR","sourcesContent":[".wrapper{\n    padding: 25px 5px 5px;\n    width: 100%;\n}\n.day_range{\n    position: relative;\n    height: 10px;\n    width: 100%;\n}\n\n.range{\n    position: absolute;\n    height: 10px;\n    top: 0;\n}\n.label_from{\n    position: absolute;\n    left: 0;\n    top: -20px;\n    transform: translateX(-50%);\n}\n.label_to{\n    position: absolute;\n    right: 0;\n    top: -20px;\n    transform: translateX(50%);\n\n    &.down{\n        top: 9px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TimeRanges_wrapper__iavaS",
	"day_range": "TimeRanges_day_range__rxFF+",
	"range": "TimeRanges_range__mQ40Y",
	"label_from": "TimeRanges_label_from__jPloc",
	"label_to": "TimeRanges_label_to__Q9Vxh",
	"down": "TimeRanges_down__klOyR"
};
export default ___CSS_LOADER_EXPORT___;
