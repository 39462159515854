import React from 'react';

import {useParams} from 'react-router';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import {Pages} from '../constants/pages';
import NotFound from './NotFound';
import Instance from '../components/Instances/Instance/Instance';
import {instanceAPI} from '../api/instanceAPI';

const InstancePage: React.FC = () => {

    const {cloudId, instanceId} = useParams();

    if (!cloudId || !instanceId) {
        return <NotFound/>;
    }

    const {data: item, isLoading} = instanceAPI.useGetInstanceQuery(Number(instanceId));
    return (
        <MainLayout pageTitle={Pages.ACCESSES} isLoading={isLoading}>
            <div className="row">
                <div className="col-lg-6">
                    {
                        item && <Instance item={item} accessId={Number(cloudId)}/>
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default InstancePage;