import React from 'react';

import {Helmet} from 'react-helmet-async';

import WithSpinner from '../../../hoc/withSpinner';

export interface BaseLayoutProps  {
    children?: React.ReactNode;
    pageTitle?: string;
    isLoading?: boolean;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({pageTitle, isLoading, children}) => {
    return (
        <WithSpinner isSpinnerVisible={isLoading ?? false}>
            {
                pageTitle && <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
            }
            {children}
        </WithSpinner>
    );
};

export default BaseLayout;