import React from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';

import FormGroup from '../../UI/FormGroup/FormGroup';
import PasswordInput from '../../UI/Input/PasswordInput';
import Alert from '../../UI/Alert/Alert';
import PrimaryButton from '../../UI/Button/PrimaryButton';
import INewPasswordForm from '../../../models/forms/INewPasswordForm';

interface INewPasswordFormProps {
    onSubmit: SubmitHandler<INewPasswordForm>
}

const NewPasswordForm: React.FC<INewPasswordFormProps> = ({onSubmit}) => {

    const {register, handleSubmit, formState} = useForm<INewPasswordForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    return (
        <>
            <div className="row text-center mb-3">
                <div className="col-12">
                    <h2>Новый пароль</h2>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup hasError={errors.password !== undefined}>
                    <PasswordInput
                        register={register}
                    />
                    {errors.password && <Alert>{errors.password.message}</Alert>}
                </FormGroup>

                <FormGroup hasError={errors.password_confirmation !== undefined}>
                    <PasswordInput
                        register={register}
                        name="password_confirmation"
                        placeholder="Подтверждение пароля"
                    />
                    {errors.password_confirmation && (
                        <Alert>{errors.password_confirmation.message}</Alert>
                    )}
                </FormGroup>

                <div className="form-group">
                    <PrimaryButton
                        type="submit"
                        className="full-width"
                        disabled={!isDirty || !isValid}
                    >Изменить пароль</PrimaryButton>
                </div>
            </form>
        </>
    );
};

export default NewPasswordForm;