// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BlankLayout_wrapper__Eop7h {\n  padding: 0 1rem;\n  width: 100%;\n  min-height: 100%;\n  background-color: #f3f3f4;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/Layout/BlankLayout/BlankLayout.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;AACJ","sourcesContent":[".wrapper {\n    padding: 0 1rem;\n    width: 100%;\n    min-height: 100%;\n    background-color: #f3f3f4;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "BlankLayout_wrapper__Eop7h"
};
export default ___CSS_LOADER_EXPORT___;
