import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faCog} from '@fortawesome/free-solid-svg-icons';

import IAccess from '../../models/Access/IAccess';
import InstanceRow from './InstanceRow';

interface IInstancesProps {
    access: IAccess
}

const Instances: React.FC<IInstancesProps> = ({access}) => {
    return (
        <table className="table table-striped table-hover table-condensed">
            <thead>
                <tr>
                    <th>Имя</th>
                    <th>Состояние</th>
                    <th>Расписание</th>
                    <th>Экономия</th>
                    <th style={{width: '70px'}}>
                        <FontAwesomeIcon icon={faCog} size="2x" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    access.instances.map(instance => (
                        <InstanceRow key={instance.id} item={instance} access={access}/>
                    ))
                }
            </tbody>
        </table>
    );
};

export default Instances;