import dateFormatter from 'date-and-time';
import ru from 'date-and-time/locale/ru';

const defaultTimeZone = 'Europe/Moscow';

interface IDateOptions {
    tzString?: string | null,
    format?: string | null,
    nullValue?: string | null,
}

// Параметры по умолчанию
const defaultDateOptions: IDateOptions = {
    tzString: null,
    format: 'HH:mm, DD.MM.YYYY',
    nullValue: '-',
};

/**
 * Форматирование даты
 * @param dateString - Дата
 * @param options - Опции
 * @return string
 */
export function getFormattedDate(
    dateString?: Date|string|null,
    options: IDateOptions = defaultDateOptions,
):string {
    options = {...defaultDateOptions, ...options};
    dateFormatter.locale(ru);
    // Если не передан dateString
    if (null === dateString || typeof dateString === 'undefined') {
        return <string>options.nullValue ?? defaultDateOptions.nullValue;
    }

    const convertedDate = convertTZ(dateString, options.tzString);
    return dateFormatter.format(convertedDate, <string>options.format ?? defaultDateOptions.format);
}

export function currentDatePlusHours(hours: number): Date{
    const date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);

    return date;
}

function convertTZ(date: string|Date, tzString: string|null|undefined) {
    return new Date((typeof date === 'string' ? new Date(date) : date)
        .toLocaleString('en-US', {timeZone: tzString ?? getClientDefaultTimeZone()}));
}

export function getClientDefaultTimeZone() {
    if (typeof Intl === 'undefined') {
        return defaultTimeZone;
    }

    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getMinutesFromDayBeginning(
    dateString: Date|string,
    tzString?: string|null,
):number {
    const date = new Date(convertTZ(dateString, tzString));
    return date.getHours() * 60 + date.getMinutes();
}
