import React from 'react';

import {useSelector} from 'react-redux';

import BlankLayout from '../../components/Layout/BlankLayout/BlankLayout';
import NewPassword from '../../components/Auth/Password/NewPassword';
import {isPasswordSettingSelector} from '../../redux/authSlice';


const NewPasswordPage: React.FC = () => {
    const isLoading = useSelector(isPasswordSettingSelector);
    return (
        <BlankLayout pageTitle="Новый пароль" isLoading={isLoading}>
            <NewPassword/>
        </BlankLayout>
    );
};

export default NewPasswordPage;