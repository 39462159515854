export const ApiUrls = {
    TOKEN_REFRESH: '/token/refresh',
    MERCURE_TOKEN_ANON: (uuid: string) => `/mercure/token?uuid=${uuid}`,
    TOKEN: '/token',
    EMAIL_RECONFIRM: '/users/email/confirm/send',
    USERS: '/users',
    USER: (id: number) => `/users/${id}`,
    ME: '/users/me',
    PASSWORD_RESET_REQUEST: '/users/password-reset/request',
    PASSWORD_RESET_CONFIRM: (userId: number, token: string) => `/users/${userId}/password-reset/confirm/${token}`,
    EMAIL_CONFIRM: (userId: number, token: string) => `/users/${userId}/email/confirm/${token}`,
    ACCESSES: '/accesses',
    ACCESS: (id: number) => `/accesses/${id}`,
    SERVICES: '/services',
    SERVICE: (id: number) => `/services/${id}`,
    INSTANCE: (id: number) => `/instances/${id}`,
    INSTANCE_CHANGE_STATUS: (id: number) => `/instances/${id}/change-status`,
    ACCESS_LINKS: '/access-links',
    ACCESS_LINK: (uuid: string) => `/access-links/${uuid}`,
    INSTANCE_ANON: (uuid: string) => `/anon/access-links/${uuid}`,
    SCHEDULES: '/schedules',
    SCHEDULE: (id: number) => `/schedules/${id}`,
    SUMMARY: '/summary',
};

export const AppUrls = {
    HOME: '/',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    PASSWORD_RESET: '/password/reset',
    PASSWORD_RESET_SENT: '/password/reset/sent',
    NEW_PASSWORD: '/password/reset/:userId/:token',
    EMAIL_CONFIRMATION: '/email/confirm/:userId/:token',
    FORBIDDEN: '/forbidden',
    SETTINGS: '/settings',
    CLOUDS: '/clouds',
    CLOUD: '/clouds/:cloudId',
    INSTANCE: '/clouds/:cloudId/instances/:instanceId',
    INSTANCE_ANON: '/anon/access-links/:uuid',
    SCHEDULES: '/schedules',
    MANUAL_MANAGEMENT: '/manual-management',
    PRIVACY_POLICY: '#',
    LICENSE_AGREEMENT: '#',

};