import React from 'react';

import Input, {InputProps} from './Input';

type PasswordInputProps = Omit<InputProps,
    'type' | 'minLength' | 'minLengthErrorMsg' | 'errorMsg'>;

const PasswordInput: React.FC<PasswordInputProps> = (props) => {

    return (
        <Input
            type="password"
            name="password"
            placeholder="Пароль"
            minLength={10}
            minLengthErrorMsg="Не менее 10 символов"
            errorMsg="Необходимо ввести пароль"
            {...props}
        />
    );
};

export default PasswordInput;