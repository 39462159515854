import React from 'react';

import cn from 'classnames';

import {NormalizedStatus} from '../../models/Instance/NormalizedStatus';
import {ManagementStatus} from '../../models/Instance/ManagementStatus';
import {colorStatus, translateStatus} from '../../utils/common';

import styles from './StatusBadge.module.scss';

interface IStatusBadgeProps {
    status: string,
    normalizedStatus: NormalizedStatus,
    managementStatus: ManagementStatus,
}

const StatusBadge: React.FC<IStatusBadgeProps> = ({
    status,
    normalizedStatus,
    managementStatus,
}) => {
    const isDisappeared = managementStatus === ManagementStatus.Disappeared;

    const backgroundColor = isDisappeared ? 'grey' : colorStatus(normalizedStatus);
    const translatedStatus = isDisappeared ? 'Не доступна' : translateStatus(normalizedStatus, status);
    return (
        <div className={cn([styles.status, 'badge'])} style={{backgroundColor}}>
            {translatedStatus}
        </div>
    );
};

export default StatusBadge;