import React, {MouseEvent} from 'react';

import {generatePath, useNavigate} from 'react-router-dom';

import IInstance from '../../models/Instance/IInstance';
import EconomyCharts from '../UI/EconomyCharts/EconomyCharts';
import InstanceMenu from './InstanceMenu';
import {AppUrls} from '../../constants/urls';
import IAccess from '../../models/Access/IAccess';
import StatusBadge from '../Status/StatusBadge';

interface IInstanceRowProps {
    item: IInstance,
    access: IAccess,
}

const InstanceRow: React.FC<IInstanceRowProps> = ({item, access}) => {
    const navigate = useNavigate();
    const onTrClick = (event: MouseEvent<HTMLTableRowElement>) => {
        event.preventDefault();
        navigate(generatePath(AppUrls.INSTANCE, { instanceId: item.id, cloudId: access.id }));
    };

    return (
        <tr onClick={onTrClick} className="clickable">
            <td>{item.name}</td>
            <td>
                <StatusBadge
                    status={item.status}
                    normalizedStatus={item.normalized_status}
                    managementStatus={item.management_status}
                />
            </td>
            <td><EconomyCharts items={item.economy_by_days} /></td>
            <td>{item.economy_average}%</td>
            <td>
                <div className="d-flex justify-content-center">
                    <InstanceMenu item={item} access={access}/>
                </div>
            </td>
        </tr>
    );
};

export default InstanceRow;