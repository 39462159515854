import React from 'react';

import LinkButton from '../../UI/Button/LinkButton';
import {AppUrls} from '../../../constants/urls';


const NewPasswordComplete: React.FC = () => {

    return (
        <>
            <div className="row text-center mb-3">
                <div className="col-12">
                    <h2>Пароль успешно изменен</h2>
                </div>
            </div>

            <LinkButton
                href={AppUrls.HOME}
                className="btn btn-primary full-width mb-2 mb-sm-0"
            >Продолжить</LinkButton>
        </>
    );
};

export default NewPasswordComplete;