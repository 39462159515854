import {Action, Dispatch} from 'redux';
import {isEmpty} from 'lodash';

import {MercureService} from '../../utils/MercureService';
import IInstanceStatusChanged from '../../models/Instance/IInstanceStatusChanged';
import {instanceAPI} from '../../api/instanceAPI';
import {accessAPI} from '../../api/accessAPI';
import {isBrowser} from '../../utils/evironment';


let anonUuid = '';
// eslint-disable-next-line no-unused-vars
const mercureMiddleware = ({dispatch}: { dispatch: Dispatch }) =>
    // eslint-disable-next-line no-unused-vars
    (next: (arg0: Action) => any) =>
        async(action: Action) => {
            if (action.type === 'auth/getMercureTokenAnon/pending') {
                // @ts-ignore
                anonUuid = action.meta.arg;
            }

            if (
                isBrowser()
                && (action.type === 'auth/fetchMe/fulfilled'
                || action.type === 'auth/getMercureTokenAnon/fulfilled')
            ) {
                const mercureService = MercureService.getInstance();
                mercureService.startConnect();
                mercureService.anonUuid = action.type === 'auth/getMercureTokenAnon/fulfilled'
                    ? anonUuid
                    : '';

                mercureService.setOnMessageListener((ev) => {
                    const result: IInstanceStatusChanged = JSON.parse(ev.data);

                    const {id, anon_uuids: anonUuids, access_ids: accessIds, ...patch} = result;

                    dispatch(
                        // @ts-ignore
                        instanceAPI.util.updateQueryData(
                            'getInstance',
                            id,
                            (draft) => {
                                Object.assign(draft, patch);
                            }),
                    );

                    if (!isEmpty(anonUuids)) {
                        for (const uuid of anonUuids) {
                            dispatch(
                                // @ts-ignore
                                instanceAPI.util.updateQueryData(
                                    'getInstanceAnon',
                                    uuid,
                                    (draft) => {
                                        Object.assign(draft.instance, patch);
                                    }),
                            );
                        }
                    }

                    if (!isEmpty(accessIds)) {
                        for (const accessId of accessIds) {
                            dispatch(
                                accessAPI.util.invalidateTags([{type: 'Access', id: accessId}]),
                            );
                        }
                    }
                });
            }
            return next(action);
        };

export default mercureMiddleware;