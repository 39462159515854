import {createApi} from '@reduxjs/toolkit/query/react';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';
import ISummary from '../models/Summary';

export const summaryAPI = createApi({
    reducerPath: 'summaryAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['Summary'],
    refetchOnMountOrArgChange: 60,
    endpoints: (build) => ({
        getSummaryItems: build.query<ISummary[], void>({
            query: () => ({
                path: ApiUrls.SUMMARY,
            }),
            providesTags: ['Summary'],
        }),
    }),
});