import React, {useEffect} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';

import {useDispatch} from 'react-redux';

import {useNavigate} from 'react-router-dom';

import MainMenu from '../../UI/MainMenu/MainMenu';

import EmailConfirmationBanner from '../../EmailConfirmationBanner/EmailConfirmationBanner';

import CustomImage from '../../UI/Image/CustomImage';
import Logo from '../../../images/logo.png';

import {logout} from '../../../utils/auth';
import {AppUrls} from '../../../constants/urls';
import {TypedDispatch} from '../../../types';

import ExitButton from '../../UI/IconButton/ExitButton';

import BaseLayout, {BaseLayoutProps} from '../BaseLayout/BaseLayout';

import styles from './MainLayout.module.scss';


const MainLayout: React.FC<BaseLayoutProps> = (props) => {
    // Костыли из Inspinia
    const updateBodyClasses = (): void => {
        const body: HTMLBodyElement = document.getElementsByTagName('body')[0];

        if (window.innerWidth < 769) {
            body.classList.add('body-small');
        } else {
            body.classList.remove('body-small');
        }
    };
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();

    useEffect(() => {
        updateBodyClasses();
        window.addEventListener('resize', updateBodyClasses);
    });

    const fadeIn = (element: HTMLElement): void => {
        element.style.opacity = '0';
        element.style.display = 'block';

        let opacity = 0;
        const intervalID = setInterval(function() {
            if (opacity < 1) {
                opacity += 0.1;
                element.style.opacity = String(opacity);
            } else {
                clearInterval(intervalID);
            }
        }, 10);
    };

    const smoothlyMenu = (): void => {
        const body: HTMLBodyElement = document.getElementsByTagName('body')[0];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const sideMenu: HTMLElement = document.getElementById('side-menu')!;

        if (!body.classList.contains('mini-navbar') || body.classList.contains('body-small')) {
            sideMenu.style.display = 'none';
            setTimeout(function() {
                fadeIn(sideMenu);
            }, 200);
        } else if (body.classList.contains('fixed-sidebar')) {
            sideMenu.style.display = 'none';
            setTimeout(function() {
                fadeIn(sideMenu);
            }, 100);
        } else {
            sideMenu.removeAttribute('style');
        }
    };

    const handleCollapseClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        const body: HTMLBodyElement = document.getElementsByTagName('body')[0];
        if (body.classList.contains('mini-navbar')) {
            body.classList.remove('mini-navbar');
        } else {
            body.classList.add('mini-navbar');
        }

        smoothlyMenu();
        e.preventDefault();
    };

    const handleLogout = async(event: React.MouseEvent) => {
        event.preventDefault();
        await logout(dispatch);
        navigate(AppUrls.LOGIN);
    };

    return (
        <BaseLayout {...props}>
            <div className={styles.layout}>
                <nav className="navbar-default navbar-static-side position-relative" role="navigation">
                    <div className="sidebar-collapse">
                        <div className="logo-wrapper">
                            <CustomImage src={Logo} alt="Overseer" width={50} height={50}/>
                            Overseer
                        </div>
                        <MainMenu/>
                    </div>
                </nav>
                <div className={styles['layout-wrapper']}>
                    <header className={styles['layout__header']}>
                        <div className={styles['layout-container']}>
                            <div className={styles['layout__header-items']}>
                                <a className="navbar-minimalize btn btn-primary"
                                    href="#"
                                    onClick={(e) => handleCollapseClick(e)}
                                >
                                    <FontAwesomeIcon icon={faBars}/>
                                </a>
                                <ExitButton onClick={handleLogout} title="Выход"/>

                            </div>
                        </div>
                    </header>

                    <EmailConfirmationBanner/>

                    {
                        props.pageTitle && (
                            <div className="row wrapper border-bottom white-bg page-heading">
                                <div className="col-12">
                                    <div
                                        className={'d-sm-flex align-items-center justify-content-between'}
                                    >
                                        <h2>{props.pageTitle}</h2>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <main className={styles['layout__main']}>
                        <div className={styles['layout-container']}>
                            {props.children}
                        </div>
                    </main>

                    <footer className={styles['layout__footer']}></footer>
                </div>
            </div>
        </BaseLayout>
    );
};

export default MainLayout;