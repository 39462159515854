import React, {ComponentProps} from 'react';
import cn from 'classnames';


interface BaseInputProps{
    register?: any,
    errorMsg?: string | boolean | object | null;
    minLength?: number,
    minLengthErrorMsg?: string,
    constraints?: object;
}

export type InputProps = BaseInputProps & Omit<Omit<ComponentProps<'input'>, keyof BaseInputProps>, 'ref'>;

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const Component: React.FC<InputProps> = ({
        name,
        className,
        register,
        errorMsg,
        minLength,
        minLengthErrorMsg,
        constraints,
        ...rest
    }) => (
        <input
            ref={ref}
            name={name}
            className={cn('form-control', className)}
            {...register && register(`${name}`, {
                required: errorMsg,
                ...minLength && {minLength: {value: minLength, message: minLengthErrorMsg}},
                ...constraints,
            })}
            {...rest}
        />
    );

    return <Component {...props} />;
});

Input.displayName = 'Input';

export default React.memo(Input);