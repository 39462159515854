import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';

import {TypedDispatch} from '../../types';
import {emailConfirmationRequest, userSelector} from '../../redux/authSlice';

import styles from './EmailConfirmationBanner.module.scss';


const EmailConfirmationBanner: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const user = useSelector(userSelector);

    const [linkSent, setLinkSent] = useState(false);

    const clickHandler = async() => {
        const resultAction = await dispatch(emailConfirmationRequest());
        const response = unwrapResult(resultAction);
        toast.info(response.message);
        setLinkSent(true);
    };


    return (
        user && !user.email_confirmed
            ? <div className={styles.banner} data-testid="email-confirmation-banner">
                {linkSent
                    ? <div className={styles.banner__text}>Ссылка для активации отправлена, проверьте вашу почту</div>
                    : <div className={styles.banner__text}>
                        Необходимо подтвердить ваш адрес {user.email}! &nbsp;
                        <button className={styles.banner__btn} onClick={clickHandler}>
 Отправить ссылку для активации повторно
                        </button>
                    </div>
                }
            </div>
            : <></>
    );
};

export default EmailConfirmationBanner;