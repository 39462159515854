import React from 'react';

import IIEconomyWithDay from '../../../models/IEconomyWithDay';
import EconomyChart from './EconomyChart';

import styles from './EconomyCharts.module.scss';

interface IEconomyChartsProps {
    items: IIEconomyWithDay[]
}

const EconomyCharts: React.FC<IEconomyChartsProps> = ({items}) => {
    const isValuesSame = (items: IIEconomyWithDay[]) => {
        let value: number|null = null;
        let same = true;
        for (const item of items) {
            if (value === null) {
                value = item.value;
            }else if(value !== item.value){
                same = false;
                break;
            }
        }
        return same;
    };

    if(
        items.length === 0
        || (isValuesSame(items) && items[0].value === 100)
    ){
        return <div className={styles.wrapper_single}>
            <div>Нет</div>
        </div>;
    }

    if (isValuesSame(items)) {
        return <div className={styles.wrapper_single}>
            <EconomyChart off={items[0].value} />
        </div>;
    }

    return <div className={styles.wrapper}>
        {
            items.map((item) => (
                <EconomyChart key={item.day} off={item.value} />
            ))
        }
    </div>;
};

export default EconomyCharts;