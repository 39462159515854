import React from 'react';

import ISummary from '../../../models/Summary';

import CustomImage from '../../UI/Image/CustomImage';

import styles from './SummaryItem.module.scss';

interface ISummaryItemProps {
    item: ISummary
}

const SummaryItem: React.FC<ISummaryItemProps> = ({item}) => {

    return (
        <div>
            <div className={styles.service_title}>
                <div className={styles.image_wrapper}>
                    <CustomImage
                        src={item.service_icon}
                        width={50}
                        height={50}
                        alt={item.service_label}
                        title={item.service_label}
                    />
                </div>
                {item.service_label}
            </div>
            <div className={styles.summary_wrapper}>
                <table className={styles.summary_table}>
                    <tbody>
                        <tr>
                            <td>Площадок:</td>
                            <td>{item.accesses_total}</td>
                        </tr>
                        <tr>
                            <td>Виртуальных машин:</td>
                            <td>{item.instances_total}</td>
                        </tr>
                        <tr>
                            <td>ВМ включено сейчас:</td>
                            <td>{item.instances_on}</td>
                        </tr>
                        <tr>
                            <td>ВМ с расписанием:</td>
                            <td>{item.instances_managed}</td>
                        </tr>
                        <tr>
                            <td>Общая экономия: </td>
                            <td>{item.economy}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SummaryItem;