// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InstanceHeader_row__IzLef {\n  display: flex;\n  justify-content: space-between;\n}\n\n.InstanceHeader_left_col__c8KM5 {\n  display: flex;\n  align-items: center;\n}\n\n.InstanceHeader_name__L1AFW {\n  font-size: 26px;\n  margin-right: 2em;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/components/Instances/Instance/InstanceHeader/InstanceHeader.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,iBAAA;AACJ","sourcesContent":[".row{\n    display: flex;\n    justify-content: space-between;\n}\n\n.left_col{\n    display: flex;\n    align-items: center;\n}\n\n.name{\n    font-size: 26px;\n    margin-right: 2em;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "InstanceHeader_row__IzLef",
	"left_col": "InstanceHeader_left_col__c8KM5",
	"name": "InstanceHeader_name__L1AFW"
};
export default ___CSS_LOADER_EXPORT___;
