import React from 'react';

import {faCheck} from '@fortawesome/free-solid-svg-icons';

import IconButton, {IIconButtonProps} from './IconButton';

type ISaveButtonProps = Omit<IIconButtonProps, 'icon'>;

const SaveButton: React.FC<ISaveButtonProps> = (props) => {

    return (
        <IconButton icon={faCheck} {...props}/>
    );
};

export default SaveButton;