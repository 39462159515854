import React from 'react';
import cn from 'classnames';
import {Link} from 'react-router-dom';


export interface LinkButtonProps {
    href: string,
    className: string,
    children: React.ReactNode,
}

const LinkButton : React.FC<LinkButtonProps> = props => {
    return (
        <Link
            to={props.href}
            className={cn('btn', [props.className])}
        >
            {props.children}
        </Link>
    );
};

export default React.memo(LinkButton);