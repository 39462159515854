import React from 'react';
import Modal from 'rsuite/Modal';

import CreateAccessFrom from './CreateAccessForm/CreateAccessFrom';

interface ICreateAccessModalProps {
    open: boolean,
    handleClose: () => void;
}

const CreateAccessModal: React.FC<ICreateAccessModalProps> = ({open, handleClose}) => {
    return (
        <Modal
            backdrop="static"
            keyboard={false}
            open={open}
            onClose={handleClose}
            size="md"
        >
            <Modal.Header>
                <Modal.Title>Добавить площадку</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <CreateAccessFrom onSuccess={handleClose}/>
            </Modal.Body>
        </Modal>
    );
};

export default CreateAccessModal;