import React from 'react';

import {colorRangeType, translateDay} from '../../../../utils/common';

import IScheduleDay from '../../../../models/Schedule/IScheduleDay';

import ITimeRange from '../../../../models/ITimeRange';
import TimeRanges from '../../../UI/TimeRanges/TimeRanges';

import styles from './Schedules.module.scss';

interface IScheduleRowProps {
    scheduleDay: IScheduleDay,
    tzString?: string|null,
}

const ScheduleRow: React.FC<IScheduleRowProps> = ({scheduleDay, tzString}) => {
    const ranges:ITimeRange[] = scheduleDay.ranges.map(range => ({
        timeFrom: range.time_from,
        timeTo: range.time_to,
        color: colorRangeType(range.type),
    }));

    return (
        <div className={styles.row}>
            <div className={styles.day}>{translateDay(scheduleDay.day)}</div>
            <TimeRanges ranges={ranges} backgroundColor="#cccccc" tzString={tzString} />
        </div>
    );
};

export default ScheduleRow;