// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatusBadge_status__6o\\+M7 {\n  color: #ffffff;\n  font-size: 14px;\n  vertical-align: central;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/components/Status/StatusBadge.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,uBAAA;EACA,iBAAA;AACJ","sourcesContent":[".status{\n    color: #ffffff;\n    font-size: 14px;\n    vertical-align: central;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "StatusBadge_status__6o+M7"
};
export default ___CSS_LOADER_EXPORT___;
