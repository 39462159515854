// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateAccessLinkForm_form__bCDl1 {\n  min-width: 400px;\n}\n\n.CreateAccessLinkForm_description__i30eX {\n  font-size: 16px;\n  margin-bottom: 2em;\n}", "",{"version":3,"sources":["webpack://./src/components/Instances/Instance/AccessLinks/CreateAccessLinkForm/CreateAccessLinkForm.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".form{\n    min-width: 400px;\n}\n\n.description{\n    font-size: 16px;\n    margin-bottom: 2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "CreateAccessLinkForm_form__bCDl1",
	"description": "CreateAccessLinkForm_description__i30eX"
};
export default ___CSS_LOADER_EXPORT___;
