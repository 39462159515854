import {createApi} from '@reduxjs/toolkit/query/react';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';
import IInstance from '../models/Instance/IInstance';
import IChangeManagementStatusInput from '../models/Instance/IChangeManagementStatusIntut';
import IInstanceAnon from '../models/Instance/IInstanceAnon';
import IInstanceChangeStatusInput from '../models/Instance/IInstanceChangeStatusInput';
import {iri} from '../utils/stringHelper';

export const instanceAPI = createApi({
    reducerPath: 'instanceAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['Instance'],
    refetchOnMountOrArgChange: 60,
    endpoints: (build) => ({
        getInstance: build.query<IInstance, number>({
            query: (id) => ({
                path: ApiUrls.INSTANCE(id),
            }),
            providesTags: (result, error, id) => [{ type: 'Instance', id }],
        }),
        getInstanceAnon: build.query<IInstanceAnon, string>({
            query: (uuid) => ({
                path: ApiUrls.INSTANCE_ANON(uuid),
                isAnonymous: true,
            }),
        }),
        updateInstance: build.mutation<IInstance, IChangeManagementStatusInput>({
            query: ({id, ...data}) => ({
                path: ApiUrls.INSTANCE(id ?? 0),
                method: 'PUT',
                data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Instance', id }],
        }),
        changeInstanceStatus: build.mutation<void, IInstanceChangeStatusInput>({
            query: ({id, targetStatus, accessId}) => ({
                path: ApiUrls.INSTANCE_CHANGE_STATUS(id ?? 0),
                method: 'POST',
                data: {
                    target_status: targetStatus,
                    access: iri('accesses', accessId),
                },
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Instance', id }],
        }),
        deleteInstance: build.mutation<void, {id: number, accessId: number}>({
            query: ({id}) => ({
                path: ApiUrls.INSTANCE(id),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { accessId }) => [{ type: 'Instance', id: `LIST${accessId}`}],
        }),
    }),
});