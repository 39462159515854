import React from 'react';
import {Routes} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {faHome, faKey, faUser} from '@fortawesome/free-solid-svg-icons';

import {AppUrls} from './constants/urls';

import {fetchMe, isAuthenticatedSelector, isUserLoadingSelector, userSelector} from './redux/authSlice';
import {TypedDispatch} from './types';
import {getProtectedRoute, IProtectedRouteProps} from './utils/permissionsHelper';
import {getAccessToken, getRefreshToken} from './utils/auth';
import NoAuth from './pages/NoAuth';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import HomePage from './pages/HomePage';
import {Permission} from './models/Permission';
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import PasswordResetInitPage from './pages/password/PasswordResetInitPage';
import PasswordResetSentPage from './pages/password/PasswordResetSentPage';
import NewPasswordPage from './pages/password/NewPasswordPage';
import ProfilePage from './pages/ProfilePage';
import CloudsPage from './pages/CloudsPage';
import {Pages} from './constants/pages';
import CloudPage from './pages/CloudPage';
import InstancePage from './pages/InstancePage';
import InstanceAnonPage from './pages/InstanceAnonPage';

export const routes: IProtectedRouteProps[] = [
    {
        path: AppUrls.LOGIN,
        element: <LoginPage/>,
    },
    {
        path: AppUrls.REGISTRATION,
        element: <RegistrationPage/>,
    },
    {
        path: AppUrls.EMAIL_CONFIRMATION,
        element: <ConfirmEmailPage/>,
    },
    {
        path: AppUrls.PASSWORD_RESET,
        element: <PasswordResetInitPage/>,
    },
    {
        path: AppUrls.PASSWORD_RESET_SENT,
        element: <PasswordResetSentPage/>,
    },
    {
        path: AppUrls.NEW_PASSWORD,
        element: <NewPasswordPage/>,
    },
    {
        path: AppUrls.INSTANCE_ANON,
        element: <InstanceAnonPage/>,
    },
    {
        path: AppUrls.FORBIDDEN,
        element: <NoAuth/>,
    },
    {
        path: AppUrls.HOME,
        element: <HomePage/>,
        auth: [Permission.USER],
        title: Pages.HOME,
        icon: faHome,
    },
    {
        path: AppUrls.CLOUDS,
        element: <CloudsPage/>,
        auth: [Permission.USER],
        title: Pages.ACCESSES,
        icon: faKey,
    },
    {
        path: AppUrls.CLOUD,
        element: <CloudPage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.INSTANCE,
        element: <InstancePage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.SETTINGS,
        element: <ProfilePage/>,
        auth: [Permission.USER],
        title: Pages.SETTINGS,
        icon: faUser,
    },
];

const Router = () => {
    const user = useSelector(userSelector);
    const isUserLoading = useSelector(isUserLoadingSelector);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const dispatch = useDispatch<TypedDispatch>();

    if (
        (isAuthenticated || getAccessToken() || getRefreshToken())
        && null === user
        && !isUserLoading
    ) {
        dispatch(fetchMe());
    }

    return (
        <Routes>
            {
                routes.map(routeProps => getProtectedRoute(routeProps))
            }
        </Routes>
    );
};

export default Router;