import React, {ReactNode} from 'react';

import cn from 'classnames';

import styles from './DisappearedOverlay.module.scss';

interface IDisappearedOverlayProps {
    isDisappeared: boolean,
    children: ReactNode,
}

const DisappearedOverlay: React.FC<IDisappearedOverlayProps> = ({isDisappeared,
    children}) => {

    return (
        <div className={styles.wrapper}>
            {children}
            <div className={cn([
                {[styles.overlay]: isDisappeared},
            ])}/>
        </div>
    );
};

export default DisappearedOverlay;