// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InstanceAnon_wrapper__e4G4p {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.InstanceAnon_box_wrapper__ak1dS {\n  width: 100%;\n  max-width: 700px;\n  margin: 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/components/Instances/Instance/InstanceAnon/InstanceAnon.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;EACA,cAAA;AACJ","sourcesContent":[".wrapper {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.box_wrapper {\n    width: 100%;\n    max-width: 700px;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "InstanceAnon_wrapper__e4G4p",
	"box_wrapper": "InstanceAnon_box_wrapper__ak1dS"
};
export default ___CSS_LOADER_EXPORT___;
