import React from 'react';

import ITimeRange from '../../../models/ITimeRange';
import {getFormattedDate, getMinutesFromDayBeginning} from '../../../utils/datetime';
import TimeRange from './TimeRange';

interface ITimeRangeControlProps {
    range: ITimeRange,
    tzString?: string|null
}

const TimeRangeControl: React.FC<ITimeRangeControlProps> = ({range, tzString}) => {
    const minutesInDay = 60 * 24;

    const formatDate = (datetime: string): string => getFormattedDate(datetime, {format: 'HH:mm', tzString});

    const getWidth = (from: number, to: number): string => `${Math.round((to - from) / minutesInDay * 100)}%`;

    const getLeft = (from: number): string => `${Math.round(from / minutesInDay * 100)}%`;

    const minutesFrom = getMinutesFromDayBeginning(range.timeFrom, tzString);
    const minutesTo = getMinutesFromDayBeginning(range.timeTo, tzString);

    if (minutesFrom > minutesTo) {
        //Верхняя часть интервала выходит за пределы дня - разбиваем на 2 интервала
        return <>
            <TimeRange
                width={getWidth(minutesFrom, minutesInDay)}
                left={getLeft(minutesFrom)}
                color={range.color}
                timeFrom={formatDate(range.timeFrom)}
                timeTo={'23:59'}
            />
            {
                minutesTo > 0
                && <TimeRange
                    width={getWidth(0, minutesTo)}
                    left={'0%'}
                    color={range.color}
                    timeFrom={'00:00'}
                    timeTo={formatDate(range.timeTo)}
                />
            }
        </>;
    }

    //Интервал в пределах дня
    return <TimeRange
        width={getWidth(minutesFrom, minutesTo)}
        left={getLeft(minutesFrom)}
        color={range.color}
        timeFrom={formatDate(range.timeFrom)}
        timeTo={formatDate(range.timeTo)}
    />;
};

export default TimeRangeControl;