import React, {useCallback} from 'react';

import {generatePath} from 'react-router-dom';

import {useSelector} from 'react-redux';

import {toast} from 'react-toastify';

import IInstance from '../../../models/Instance/IInstance';
import NotFound from '../../../pages/NotFound';
import BackButton from '../../UI/IconButton/BackButton';
import {AppUrls} from '../../../constants/urls';
import Box from '../../UI/Box/Box';
import InstanceHeader from './InstanceHeader/InstanceHeader';
import InstanceManagement from './InstanceManagement';
import SchedulesOverview from './Schedules/SchedulesOverview';
import PrimaryButton from '../../UI/Button/PrimaryButton';
import AccessLinks from './AccessLinks/AccessLinks';
import {ManagementStatus} from '../../../models/Instance/ManagementStatus';
import DisappearedOverlay from './DisappearedOverlay/DisappearedOverlay';
import {userSelector} from '../../../redux/authSlice';
import {instanceAPI} from '../../../api/instanceAPI';

interface IInstanceProps {
    item: IInstance,
    accessId: number,
}

const Instance: React.FC<IInstanceProps> = ({item, accessId}) => {
    const access = item.accesses.find(a => a.id === accessId);
    const user = useSelector(userSelector);
    const [changeStatus] = instanceAPI.useChangeInstanceStatusMutation();
    if (!access) {
        return <NotFound />;
    }
    const cloudUrl = generatePath(AppUrls.CLOUD, { cloudId: accessId });
    const isDisappeared = item.management_status === ManagementStatus.Disappeared;

    const onClickOn = useCallback(async() => {
        await changeStatus({
            id: item.id,
            targetStatus: 'on',
            accessId,
        }).unwrap();
        toast.info('Отправлен запрос на включение ВМ');
    }, [item.id, accessId]);

    const onClickOff = useCallback(async() => {
        await changeStatus({
            id: item.id,
            targetStatus: 'off',
            accessId,
        }).unwrap();

        toast.info('Отправлен запрос на выключение ВМ');
    }, [item.id, accessId]);

    const onClickRestart = useCallback(async() => {
        await changeStatus({
            id: item.id,
            targetStatus: 'restart',
            accessId: access.id,
        }).unwrap();

        toast.info('Отправлен запрос на перезапуск ВМ');
    }, [item.id, accessId]);

    return (
        <>
            <div className="mb-2">
                <BackButton to={cloudUrl} text={access.label}/>
            </div>
            <Box>
                <div className="mb-3">
                    <InstanceHeader
                        item={item}
                        cloudUrl={cloudUrl}
                        onClickOn={onClickOn}
                        onClickOff={onClickOff}
                        onClickRestart={onClickRestart}
                        isAnon={false}
                    />
                </div>
                <DisappearedOverlay isDisappeared={isDisappeared}>
                    <div className="mb-3">
                        <InstanceManagement item={item}/>
                    </div>
                    <SchedulesOverview
                        items={item.schedules_by_days}
                        turnedOnHours={item.turned_on_hours}
                        economy={item.economy_average}
                        tzString={user?.timezone}
                    />
                    <div className="my-3">
                        <PrimaryButton>Изменить расписание</PrimaryButton>
                    </div>
                    <AccessLinks item={item} accessId={accessId} />
                </DisappearedOverlay>
            </Box>
        </>
    );
};

export default Instance;