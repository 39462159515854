import {NormalizedStatus} from '../models/Instance/NormalizedStatus';
import {DayOfWeek, ScheduleRangeType} from '../types';

export const wordDeclension = (number: number, txt: string[], cases = [2, 0, 1, 1, 1, 2]) => (
    txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
);

export const translateStatus = (normalizedStats: NormalizedStatus, status: string): string => {
    switch (normalizedStats){
        case NormalizedStatus.On:
            return 'Включено';
        case NormalizedStatus.Off:
            return 'Выключено';
        case NormalizedStatus.Other:
            return `Другое (${status})`;
    }
};

export const colorStatus = (normalizedStats: NormalizedStatus): string => {
    const map = {
        [NormalizedStatus.On]: '#04b800',
        [NormalizedStatus.Off]: '#da0404',
        [NormalizedStatus.Other]: '#a3a3a3',
    };

    return map[normalizedStats];
};

export const translateDay = (day: DayOfWeek): string => {
    const map = {
        Mon: 'Пн.',
        Tue: 'Вт.',
        Wed: 'Ср.',
        Thu: 'Чт.',
        Fri: 'Пт.',
        Sat: 'Сб.',
        Sun: 'Вс.',
    };

    return map[day];
};

export const colorRangeType = (type: ScheduleRangeType): string => {
    const map = {
        UP_DOWN: '#04b800',
        UP_CONTROL_DOWN: '#056c04',
    };

    return map[type];
};