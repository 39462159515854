import React from 'react';
import cn from 'classnames';

import BaseButton, {BaseButtonProps} from './BaseButton';


const PrimaryButton: React.FC<BaseButtonProps> = props => {
    return (
        <BaseButton {...props} className={cn('btn-primary', [props.className])}>
            {props.children}
        </BaseButton>
    );
};

export default React.memo(PrimaryButton);