import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';

import {routes} from 'Router';
import {Permission} from 'models/Permission';
import {isGrantedAccess} from 'utils/permissionsHelper';
import {userPermissionsSelector} from 'redux/authSlice';

import styles from './MainMenu.module.scss';

interface IMenuItemProps {
    path: string,
    title: string,
    icon: IconDefinition,
    auth?: Permission[],
}

const MainMenu: React.FC = () => {
    const userPermissions = useSelector(userPermissionsSelector);
    const menu = routes.filter(item => item.title && item.icon) as IMenuItemProps[];

    return (
        <ul className="nav metismenu" id="side-menu">
            {
                menu.map(item => {
                    return isGrantedAccess(userPermissions, item.auth)
                        ? <li key={item.path}>
                            <NavLink
                                to={item.path}
                                end={item.path.length === 1}
                                className={({isActive}) => isActive ? styles.active_link : undefined}>
                                <FontAwesomeIcon icon={item.icon} className={styles.item_icon} size="1x"/>
                                <span className="nav-label">{item.title}</span>
                            </NavLink>
                        </li>
                        : <></>;
                })
            }
        </ul>
    );
};


export default MainMenu;