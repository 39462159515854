import React, {useEffect} from 'react';


import {Link, useNavigate} from 'react-router-dom';

import cn from 'classnames';

import {SubmitHandler, useForm} from 'react-hook-form';

import {useDispatch, useSelector} from 'react-redux';

import Box from '../UI/Box/Box';
import FormGroup from '../UI/FormGroup/FormGroup';
import EmailInput from '../UI/Input/EmailInput';
import Alert from '../UI/Alert/Alert';
import PrimaryButton from '../UI/Button/PrimaryButton';
import {AppUrls} from '../../constants/urls';


import Checkbox from '../UI/Checkbox/Checkbox';
import PasswordInput from '../UI/Input/PasswordInput';
import IRegistrationForm from '../../models/forms/IRegistrationForm';


import {TypedDispatch} from '../../types';
import {fetchMe, registerUser, registerUserSuccessSelector} from '../../redux/authSlice';
import {redirectToDashboard} from '../../utils/redirects';
import Input from '../UI/Input/Input';

import {getClientDefaultTimeZone} from '../../utils/datetime';

import styles from './Auth.module.scss';

const Registration: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();

    const {register, handleSubmit, formState} = useForm<IRegistrationForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    const registerUserSuccess = useSelector(registerUserSuccessSelector);

    const onSubmit: SubmitHandler<IRegistrationForm> = (formData) => {
        dispatch(registerUser({...formData, timezone: getClientDefaultTimeZone()}));
    };

    useEffect(() => {
        if (registerUserSuccess) {
            dispatch(fetchMe())
                .then(()=> {
                    redirectToDashboard(navigate);
                });
        }
    }, [registerUserSuccess]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <Box>
                    <div className="row text-center mb-3">
                        <div className="col-12">
                            <h2>Создайте свой аккаунт</h2>
                        </div>
                    </div>

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="register-form"
                    >


                        <FormGroup
                            hasError={errors.first_name !== undefined}
                        >
                            <Input
                                type="text"
                                name="first_name"
                                placeholder="Имя"
                                register={register}
                                required
                            />
                            {errors.first_name && <Alert>{errors.first_name.message}</Alert>}
                        </FormGroup>

                        <FormGroup
                            hasError={errors.last_name !== undefined}
                        >
                            <Input
                                type="text"
                                name="last_name"
                                placeholder="Фамилия"
                                register={register}
                                required
                            />
                            {errors.last_name && <Alert>{errors.last_name.message}</Alert>}
                        </FormGroup>

                        <FormGroup hasError={errors.email !== undefined}>
                            <EmailInput
                                register={register}
                                required
                            />
                            {errors.email && <Alert>{errors.email.message}</Alert>}
                        </FormGroup>

                        <FormGroup
                            hasError={errors.password !== undefined}
                        >
                            <PasswordInput register={register}/>
                            {errors.password && <Alert>{errors.password.message}</Alert>}
                        </FormGroup>

                        <FormGroup
                            className="mb-3"
                            hasError={errors.license_agreement !== undefined}
                        >

                            <Checkbox
                                id="license_agreement"
                                {...register(
                                    'license_agreement', {
                                        required: {
                                            value: true,
                                            message: 'Необходимо принять условия лицензионного договора',
                                        },
                                    },
                                )}
                            >
                                <span>Я принимаю условия &nbsp;
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={AppUrls.LICENSE_AGREEMENT}
                                    >лицензионного договора
                                    </Link>
                                </span>
                            </Checkbox>
                            {errors.license_agreement && <Alert>{errors.license_agreement.message}</Alert>}
                        </FormGroup>

                        <FormGroup
                            className="mb-3"
                            hasError={errors.privacy_policy !== undefined}
                        >

                            <Checkbox
                                id="privacy_policy"
                                {...register(
                                    'privacy_policy', {
                                        required: {
                                            value: true,
                                            message: 'Необходимо принять условия политики конфиденциальности',
                                        },
                                    },
                                )}
                            >
                                <span>
                                    Я даю своё согласие на обработку моей&nbsp;
                                    персональной информации на условиях, определенных &nbsp;
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={AppUrls.PRIVACY_POLICY}
                                    >политикой конфиденциальности</Link>
                                </span>
                            </Checkbox>
                            {errors.privacy_policy && <Alert>{errors.privacy_policy.message}</Alert>}
                        </FormGroup>

                        <div className="form-group">
                            <PrimaryButton
                                type="submit"
                                className="full-width"
                                disabled={!isDirty || !isValid}
                            >
                                Создать аккаунт
                            </PrimaryButton>
                        </div>
                    </form>

                    <p className={cn(styles.new_account_wrapper, 'text-muted')}>
                        Уже есть аккаунт? &nbsp;
                        <Link to={AppUrls.LOGIN} className="login-link">Войти</Link>
                    </p>
                </Box>
            </div>
        </div>
    );
};

export default Registration;