import React from 'react';

import ScheduleRow from './ScheduleRow';

import IScheduleDay from '../../../../models/Schedule/IScheduleDay';

import styles from './Schedules.module.scss';

interface ISchedulesOverviewProps {
    items: IScheduleDay[],
    turnedOnHours: number,
    economy: number,
    tzString?: string|null,
}

const SchedulesOverview: React.FC<ISchedulesOverviewProps> = ({
    items,
    tzString,
    turnedOnHours,
    economy,
}) => {

    if (items.length === 0) {
        return <div>Расписание не задано</div>;
    }

    return (
        <div>
            <div className={styles.summary}>
                Виртуальная машина включена примерно {turnedOnHours} часов из 168, ожидаемая экономия - {economy}%
            </div>
            {
                items.map((item) => (
                    <ScheduleRow
                        key={item.day}
                        scheduleDay={item}
                        tzString={tzString}
                    />
                ))
            }
        </div>
    );
};

export default SchedulesOverview;