import {ACCESS_TOKEN_NAME, MERCURE_TOKEN_NAME, REFRESH_TOKEN_NAME} from 'constants/common';

import Token from 'models/Token';
import AuthTokens from 'models/AuthTokens';

import {isBrowser} from './evironment';
import {currentDatePlusHours} from './datetime';
import {accessAPI} from '../api/accessAPI';
import {instanceAPI} from '../api/instanceAPI';
import {TypedDispatch} from '../types';
import {logoutAction} from '../redux/authSlice';
import {summaryAPI} from '../api/summaryAPI';
import {accessLinkAPI} from '../api/accessLinkAPI';
import createHttpRequest from './http';
import {ApiUrls} from '../constants/urls';

export const setTokens = (tokens: AuthTokens) => {
    if (isBrowser()) {
        setToken(ACCESS_TOKEN_NAME, tokens.token, currentDatePlusHours(1));
        setToken(REFRESH_TOKEN_NAME, tokens.refresh_token, currentDatePlusHours(30*24));
        setToken(MERCURE_TOKEN_NAME, tokens.mercure_token, currentDatePlusHours(1));
    }
};

export const clearTokens = () => {
    if (isBrowser()) {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
        localStorage.removeItem(MERCURE_TOKEN_NAME);
    }
};

export const logout = async(dispatch: TypedDispatch) => {
    clearTokens();

    await dispatch(logoutAction());
    await dispatch(accessAPI.util.resetApiState());
    await dispatch(instanceAPI.util.resetApiState());
    await dispatch(summaryAPI.util.resetApiState());
    await dispatch(accessLinkAPI.util.resetApiState());
};

export const getAccessToken = (): string | null => {
    return getToken(ACCESS_TOKEN_NAME);
};

export const getRefreshToken = (): string | null => {
    return getToken(REFRESH_TOKEN_NAME);
};

export const getMercureToken = (): string | null => {
    return getToken(MERCURE_TOKEN_NAME);
};

const getToken = (tokenName: string): string | null => {
    const tokenData: string | null = isBrowser()
        ? localStorage.getItem(tokenName)
        : null;

    if (!tokenData) {
        return null;
    }
    const token: Token | null = JSON.parse(tokenData);
    if (!token) {
        return null;
    }
    if (new Date() >= new Date(token.expires)) {
        return null;
    }

    return token.value;
};

export const setToken = (tokenName: string, value: string, expires: string|Date) => {
    const token: Token = {value, expires};
    if (isBrowser()) {
        localStorage.setItem(tokenName, JSON.stringify(token));
    }
};

export const fetchMercureTokenAnon = async(uuid: string): Promise<string> => {
    const response = await createHttpRequest({
        method: 'GET',
        path: ApiUrls.MERCURE_TOKEN_ANON(uuid),
        isAnonymous: true,
    });
    const token = response.data.token;

    setToken(MERCURE_TOKEN_NAME, token, currentDatePlusHours(1));

    return token;
};

export const refreshTokens = async():Promise<void> => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        throw new Error('Отсутствует RefreshToken');
    }
    const response = await createHttpRequest({
        method: 'POST',
        path: ApiUrls.TOKEN_REFRESH,
        data: {refresh_token: refreshToken},
        isAnonymous: true,
    });
    setTokens(response.data);
};