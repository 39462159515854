import React from 'react';

import {useSelector} from 'react-redux';

import {isUserEditingSelector, isUserLoadingSelector, userSelector} from '../redux/authSlice';
import MainLayout from '../components/Layout/MainLayout/MainLayout';
import ProfileForm from '../components/Profile/ProfileForm';
import PasswordReset from '../components/Profile/PasswordReset';
import Box from '../components/UI/Box/Box';
import {Pages} from '../constants/pages';


const ProfilePage: React.FC = () => {
    const isLoading = useSelector(isUserLoadingSelector);
    const isEditing = useSelector(isUserEditingSelector);
    const user = useSelector(userSelector);
    return (
        <MainLayout pageTitle={Pages.SETTINGS} isLoading={isLoading || isEditing}>
            <div className="row">
                <div className="col-lg-5">
                    <Box>
                        <ProfileForm user={user} />
                        <PasswordReset />
                    </Box>
                </div>
            </div>
        </MainLayout>
    );
};

export default ProfilePage;