import React, {MouseEvent, useState} from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';

import IAccess from '../../models/Access/IAccess';
import Box from '../UI/Box/Box';

import CustomImage from '../UI/Image/CustomImage';

import IAccessEditForm from '../../models/forms/IAccessEditForm';

import {accessAPI} from '../../api/accessAPI';

import EditButton from '../UI/IconButton/EditButton';
import SaveButton from '../UI/IconButton/SaveButton';
import CancelButton from '../UI/IconButton/CancelButton';

import Instances from '../Instances/Instances';

import GrowingInput from '../UI/Input/GrowingInput';

import BackButton from '../UI/IconButton/BackButton';

import {AppUrls} from '../../constants/urls';

import styles from './Cloude.module.scss';

interface ICloudProps {
    item: IAccess
}

const Cloud: React.FC<ICloudProps> = ({item}) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const {register, handleSubmit, formState} = useForm<IAccessEditForm>({mode: 'onChange'});
    const {isDirty} = formState;

    const [updateAccess] = accessAPI.useUpdateAccessMutation();

    const onSubmit: SubmitHandler<IAccessEditForm> = async(data) => {
        if (isDirty) {
            await updateAccess({id: item.id, label: data.label}).unwrap();
        }
        setIsEditMode(false);
    };

    const onEditButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsEditMode(true);
    };

    const onCancelButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsEditMode(false);
    };

    return (
        <>
            <div className="mb-2">
                <BackButton to={AppUrls.CLOUDS}/>
            </div>
            <Box>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="d-flex align-items-center">
                        <div className={styles.image_wrapper} title={item.service.label}>
                            <CustomImage
                                src={item.service.icon}
                                width={50}
                                height={50}
                                alt={item.service.label}
                                title={item.service.label}
                            />
                        </div>

                        <div>
                            {
                                isEditMode
                                    ? <GrowingInput
                                        register={register}
                                        name="label"
                                        defaultValue={item.label}
                                        required/>

                                    : <h3 style={{margin: 0}}>{item.label}</h3>
                            }

                        </div>
                        <div className={styles.buttons}>
                            {
                                isEditMode
                                    ? <>
                                        <SaveButton type="submit" title="Сохранить"/>
                                        <CancelButton title="Отменить" onClick={onCancelButtonClick}/>
                                    </>
                                    : <EditButton title="Переименовать" onClick={onEditButtonClick}/>
                            }
                        </div>
                    </div>
                </form>
                <div className="mt-2">
                    {
                        <Instances access={item}/>
                    }
                </div>
            </Box>
        </>
    );
};

export default Cloud;