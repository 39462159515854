import React from 'react';
import {useDispatch} from 'react-redux';

import {Controller, SubmitHandler, useForm} from 'react-hook-form';

import {isEmpty} from 'lodash';

import {editUser} from '../../redux/authSlice';
import {OptionType, TypedDispatch} from '../../types';
import FormGroup from '../UI/FormGroup/FormGroup';
import EmailInput from '../UI/Input/EmailInput';
import Alert from '../UI/Alert/Alert';
import Input from '../UI/Input/Input';
import PrimaryButton from '../UI/Button/PrimaryButton';

import IProfileForm from '../../models/forms/IProfileForm';
import {defaultTimeZone, timezones} from '../../constants/timezones';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import User from '../../models/User';

interface IProfileFormProps{
    user: User|null
}

const ProfileForm: React.FC<IProfileFormProps> = ({user}) => {

    const dispatch = useDispatch<TypedDispatch>();
    const {register, handleSubmit, formState, control} = useForm<IProfileForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    const onSubmit: SubmitHandler<IProfileForm> = ({timezone, ...rest}) => {
        dispatch(editUser({
            id: user?.id ?? 0,
            timezone: (timezone ?? getSelectedTimezone()).value,
            ...rest,
        }));
    };

    const getSelectedTimezone = ():OptionType => {
        const selected = user?.timezone ?? defaultTimeZone;
        return {label: selected, value: selected};
    };

    return !isEmpty(user)
        ?(
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="profile-form"
            >

                <FormGroup
                    hasError={errors.first_name !== undefined}
                >
                    <Input
                        type="text"
                        name="first_name"
                        placeholder="Имя"
                        register={register}
                        defaultValue={user.first_name}
                        required
                    />
                    {errors.first_name && <Alert>{errors.first_name.message}</Alert>}
                </FormGroup>

                <FormGroup
                    hasError={errors.last_name !== undefined}
                >
                    <Input
                        type="text"
                        name="last_name"
                        placeholder="Фамилия"
                        register={register}
                        defaultValue={user.last_name}
                        required
                    />
                    {errors.last_name && <Alert>{errors.last_name.message}</Alert>}
                </FormGroup>

                <FormGroup hasError={errors.timezone !== undefined}>
                    <label>Часовой пояс</label>
                    <Controller
                        control={control}
                        name="timezone"
                        render={({field: {ref, ...restFields}}) => (
                            <CustomSelect
                                // @ts-ignore
                                inputRef={ref}
                                options={timezones.map(zone => ({value: zone, label: zone}))}
                                defaultValue={getSelectedTimezone()}
                                {...restFields}
                            />
                        )}
                    />
                    {!!errors.timezone && <Alert>{errors.timezone.message}</Alert>}
                </FormGroup>

                <FormGroup>
                    <EmailInput
                        defaultValue={user.email}
                        disabled
                    />
                    <p>Для изменения email обратитесь в службу технической поддержки</p>
                </FormGroup>

                <FormGroup>
                    <PrimaryButton
                        type="submit"
                        className="full-width"
                        disabled={!isDirty || !isValid}
                    >
                        Сохранить
                    </PrimaryButton>
                </FormGroup>
            </form>
        )
        : <></>;
};

export default ProfileForm;