import React, {MouseEvent} from 'react';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';

import {To, useNavigate} from 'react-router-dom';

import IconButton from './IconButton';

interface IBackButtonProps{
    to: To,
    text?: string,
}

const BackButton: React.FC<IBackButtonProps> = ({to,  text}) => {
    const navigate = useNavigate();

    const onIconButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate(to);
    };
    const buttonText = text ?? 'Назад';
    return (
        <IconButton icon={faChevronLeft} title={buttonText} text={buttonText} onClick={onIconButtonClick}/>
    );
};

export default BackButton;