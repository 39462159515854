import {combineReducers} from '@reduxjs/toolkit';

import authReducer from './authSlice';
import {accessAPI} from '../api/accessAPI';
import {instanceAPI} from '../api/instanceAPI';
import {summaryAPI} from '../api/summaryAPI';
import {accessLinkAPI} from '../api/accessLinkAPI';

export default combineReducers({
    authReducer,
    [accessAPI.reducerPath]: accessAPI.reducer,
    [instanceAPI.reducerPath]: instanceAPI.reducer,
    [summaryAPI.reducerPath]: summaryAPI.reducer,
    [accessLinkAPI.reducerPath]: accessLinkAPI.reducer,
});