import React from 'react';
import {RegisterOptions} from 'react-hook-form';

import Input from './Input';

export interface EmailInputProps  {
    className?: string;
    required?: boolean,
    register?: any,
    disabled?: boolean,
    defaultValue?: string,
}


const EmailInput: React.FC<EmailInputProps> = (props) => {

    const EMAIL_PATTERN: RegExp = /^[-A-Za-z0-9!#$%&'*+\/=?^_`{|}~]+(?:\.[-A-Za-z0-9!#$%&'*+\/=?^_`{|}~]+)*@(?:[A-Za-z0-9]([-A-Za-z0-9]{0,61}[A-Za-z0-9])?\.)+(?:[A-Za-z]+)$/i; // eslint-disable-line
    const constraints: RegisterOptions = {
        pattern: {value: EMAIL_PATTERN, message: 'Некорректный Email'},
        required: 'Не указан Email',
    };
    return (
        <Input
            {...props}
            type="email"
            name="email"
            placeholder="Почта"
            constraints={constraints}
        />
    );
};

export default React.memo(EmailInput);