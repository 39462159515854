import React from 'react';

import ISummary from '../../models/Summary';
import SummaryItem from './SummaryItem/SummaryItem';

interface IHomeSummaryProps {
    items: ISummary[],
}

const HomeSummary: React.FC<IHomeSummaryProps> = ({items}) => {

    return (
        <div>
            {items.map((item) => <SummaryItem key={item.service_label} item={item} />)}
        </div>
    );
};

export default HomeSummary;