import React from 'react';

import CloudRow from './CloudRow';
import IAccess from '../../models/Access/IAccess';
import CreateAccessButton from './CreateAccessButton';

interface ICloudsProps{
    items: IAccess[]
}

const Clouds: React.FC<ICloudsProps> = ({items}) => {
    //TODO: добавить кнопку обновления списка
    return (
        <div>
            <div className="d-flex justify-content-end mb-2">
                <CreateAccessButton/>
            </div>
            
            <table className="table table-striped table-hover table-condensed">
                <thead>
                    <tr>
                        <th>Тип</th>
                        <th>Имя</th>
                        <th>ВМ</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map(access => (
                            <CloudRow key={access.id} item={access}/>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};

export default Clouds;