import React, {useEffect} from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';
import cn from 'classnames';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Box from '../UI/Box/Box';
import {TypedDispatch} from '../../types';
import {isAuthenticatedSelector, loginUser} from '../../redux/authSlice';
import ILoginForm from '../../models/forms/ILoginForm';
import FormGroup from '../UI/FormGroup/FormGroup';
import EmailInput from '../UI/Input/EmailInput';
import Alert from '../UI/Alert/Alert';
import {AppUrls} from '../../constants/urls';
import PrimaryButton from '../UI/Button/PrimaryButton';
import PasswordInput from '../UI/Input/PasswordInput';
import {redirectToDashboard, redirectToReturnUrl} from '../../utils/redirects';

import styles from './Auth.module.scss';


const Login: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const isAuthenticated = useSelector(isAuthenticatedSelector);

    const {register, handleSubmit, formState} = useForm<ILoginForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    useEffect(() => {
        if (isAuthenticated) {
            redirectToReturnUrl(navigate, searchParams) || redirectToDashboard(navigate);
        }
    }, [isAuthenticated]);

    const onSubmit: SubmitHandler<ILoginForm> = (formData) => {
        dispatch(loginUser(formData));
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <Box>
                    <div className="row text-center mb-3">
                        <div className="col-12">
                            <h2>Войдите в свой аккаунт</h2>
                        </div>
                    </div>

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="register-form"
                    >
                        <FormGroup
                            hasError={errors.email !== undefined}
                        >
                            <EmailInput
                                register={register}
                                required
                            />
                            {errors.email && <Alert>{errors.email.message}</Alert>}
                        </FormGroup>

                        <Link to={AppUrls.PASSWORD_RESET} className="login-link">
                            Забыли пароль?
                        </Link>

                        <FormGroup
                            hasError={errors.password !== undefined}
                        >
                            <PasswordInput register={register}/>
                            {errors.password && <Alert>{errors.password.message}</Alert>}
                        </FormGroup>

                        <div className="form-group">
                            <PrimaryButton
                                type="submit"
                                className="full-width"
                                disabled={!isDirty || !isValid}
                            >
                                Войти
                            </PrimaryButton>
                        </div>
                    </form>

                    <p className={cn(styles.new_account_wrapper, 'text-muted')}>
                        Еще нет аккаунта? &nbsp;
                        <Link to={AppUrls.REGISTRATION} className="register-link">Зарегистрироваться</Link>
                    </p>
                </Box>
            </div>
        </div>
    );
};

export default Login;