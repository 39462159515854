// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmailConfirmationBanner_banner__RtvTk {\n  width: 100%;\n  padding: 8px;\n  background-color: #de0202;\n  color: white;\n  text-align: center;\n  line-height: 21px;\n}\n\n.EmailConfirmationBanner_banner__text__qDG20 {\n  width: 80%;\n  max-width: 500px;\n  margin: 0 auto;\n}\n\n.EmailConfirmationBanner_banner__btn__rI8ul {\n  background-color: transparent;\n  border: none;\n  outline: none;\n  padding: 0;\n  color: white;\n  text-decoration: underline;\n}", "",{"version":3,"sources":["webpack://./src/components/EmailConfirmationBanner/EmailConfirmationBanner.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,UAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,6BAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;EACA,YAAA;EACA,0BAAA;AACJ","sourcesContent":[".banner {\n    width: 100%;\n    padding: 8px;\n    background-color: #de0202;\n    color: white;\n    text-align: center;\n    line-height: 21px;\n}\n\n.banner__text {\n    width: 80%;\n    max-width: 500px;\n    margin: 0 auto;\n}\n\n.banner__btn {\n    background-color: transparent;\n    border: none;\n    outline: none;\n    padding: 0;\n    color: white;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "EmailConfirmationBanner_banner__RtvTk",
	"banner__text": "EmailConfirmationBanner_banner__text__qDG20",
	"banner__btn": "EmailConfirmationBanner_banner__btn__rI8ul"
};
export default ___CSS_LOADER_EXPORT___;
