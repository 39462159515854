import React from 'react';

import {faEdit} from '@fortawesome/free-solid-svg-icons';

import IconButton, {IIconButtonProps} from './IconButton';

type IEditButtonProps = Omit<IIconButtonProps, 'icon'>;

const EditButton: React.FC<IEditButtonProps> = (props) => {

    return (
        <IconButton icon={faEdit} {...props}/>
    );
};

export default EditButton;