import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import Alert from 'components/UI/Alert/Alert';
import FormGroup from 'components/UI/FormGroup/FormGroup';
import PrimaryButton from 'components/UI/Button/PrimaryButton';
import WithSpinner from 'hoc/withSpinner';
import {accessLinkAPI} from 'api/accessLinkAPI';
import EmailInput from 'components/UI/Input/EmailInput';

import styles from './CreateAccessLinkForm.module.scss';

interface ICreateAccessLinkFromProps{
    instanceId: number,
    accessId: number,
    onSuccess: () => void
}

const CreateAccessLinkForm: React.FC<ICreateAccessLinkFromProps> = ({
    instanceId,
    accessId,
    onSuccess,
}) => {
    const [addAccessLink, {isLoading}] = accessLinkAPI.useAddAccessLinkMutation();
    const {register, handleSubmit, formState} = useForm<{email: string}>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    const onSubmit: SubmitHandler<{email: string}> = async({email}) => {
        await addAccessLink({
            email,
            instanceId,
            accessId,
        }).unwrap();
        
        onSuccess();
    };

    return (
        <WithSpinner isSpinnerVisible={isLoading}>
            <p className={styles.description}>
                На указанный ниже Email будет отправлена ссылка доступа, которая позволит включать и выключать ВМ.
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>

                <FormGroup
                    hasError={errors.email !== undefined}
                >
                    <EmailInput
                        register={register}
                        required
                    />
                    {errors.email && <Alert>{errors.email.message}</Alert>}
                </FormGroup>

                <FormGroup>
                    <PrimaryButton
                        type="submit"
                        className="full-width"
                        disabled={!isDirty || !isValid}
                    >
                    Предоставить доступ
                    </PrimaryButton>
                </FormGroup>
            </form>
        </WithSpinner>
    );
};

export default CreateAccessLinkForm;