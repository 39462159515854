import React, {MouseEvent} from 'react';

import IAccessLink from '../../../../models/AccessLink/IAccessLink';
import {getFormattedDate} from '../../../../utils/datetime';
import TrashButton from '../../../UI/IconButton/TrashButton';
import {accessLinkAPI} from '../../../../api/accessLinkAPI';

interface IAccessLinkProps {
    item: IAccessLink,
    instanceId: number,
    timezone?: string|null,
}

const AccessLink: React.FC<IAccessLinkProps> = ({item, instanceId, timezone}) => {
    const [deleteAccessLink] = accessLinkAPI.useDeleteAccessLinkMutation();

    const onTrashButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        deleteAccessLink({uuid: item.uuid, instanceId: instanceId});
    };

    return (
        <tr>
            <td>
                {getFormattedDate(
                    item.created_at,
                    {format: 'DD.MM.YYYY', tzString: timezone}
                )}
            </td>
            <td>
                <a href={item.url} target="_blank" rel="noreferrer">{item.email}</a>
            </td>
            <td>
                <TrashButton title="Удалить" onClick={onTrashButtonClick}/>
            </td>
        </tr>
    );
};

export default AccessLink;