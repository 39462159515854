import React from 'react';
import cn from 'classnames';


export interface BaseButtonProps {
    type?: 'button' | 'submit',
    className?: string,
    children: React.ReactNode,
    style?: object,
    disabled?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
}

const BaseButton: React.FC<BaseButtonProps> = (props) => {
    return (
        <button
            type={props.type}
            className={cn('btn', [props.className])}
            style={props.style}
            disabled={props.disabled || false}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

export default React.memo(BaseButton);


