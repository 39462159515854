import React, {useCallback} from 'react';

import {Toggle} from 'rsuite';

import IInstance from '../../../models/Instance/IInstance';
import {instanceAPI} from '../../../api/instanceAPI';
import {ManagementStatus} from '../../../models/Instance/ManagementStatus';

interface IInstanceManagementProps {
    item: IInstance,
}

const InstanceManagement: React.FC<IInstanceManagementProps> = ({item}) => {
    const [updateInstance, {isLoading}] = instanceAPI.useUpdateInstanceMutation();
    const toggle = useCallback((checked: boolean) => {
        updateInstance({
            id: item.id,
            management_status: checked
                ? ManagementStatus.Managed
                : ManagementStatus.Unmanaged,
        });
    }, [item.id]);
    const checked = item.management_status === ManagementStatus.Managed;
    return (
        <div>
            Расписание <Toggle
                loading={isLoading}
                checked={checked}
                onChange={toggle}
                checkedChildren="Включено"
                unCheckedChildren="Выключено"
            />
        </div>
    );
};

export default InstanceManagement;