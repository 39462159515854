import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {useDispatch} from 'react-redux';


import {unwrapResult} from '@reduxjs/toolkit';

import cn from 'classnames';

import Box from '../UI/Box/Box';
import {confirmEmail, fetchMe} from '../../redux/authSlice';
import {TypedDispatch} from '../../types';
import LinkButton from '../UI/Button/LinkButton';
import {AppUrls} from '../../constants/urls';

import {getAccessToken, getRefreshToken} from '../../utils/auth';

import styles from './Auth.module.scss';


const ConfirmEmail: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const {userId, token} = useParams();

    const [message, setMessage] = useState<string>();
    const [status, setStatus] = useState<boolean>(false);

    useEffect(() => {
        if (userId && token) {
            dispatch(confirmEmail({userId: Number(userId), token}))
                .then(resultAction => {
                    const response = unwrapResult(resultAction);
                    setMessage(response.message);
                    setStatus(response.status);
                    (getAccessToken() || getRefreshToken()) && dispatch(fetchMe());
                })
                .catch(error => {
                    setMessage(error.message);
                    setStatus(false);
                });
        }
    }, [userId, token]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <Box>
                    <div className="row text-center mb-3">
                        <div className="col-12">
                            <h2 className="mb-3">Подтверждение email</h2>
                            {
                                message && (
                                    <p className={cn('py-2 m-0', [status
                                        ? styles.confirm_email__msg
                                        : styles.confirm_email__msg_error])}
                                    >
                                        {message}
                                    </p>
                                )
                            }
                        </div>
                    </div>

                    <LinkButton
                        href={AppUrls.HOME}
                        className="full-width btn-primary"
                    >Продолжить</LinkButton>
                </Box>
            </div>
        </div>
    );
};

export default ConfirmEmail;