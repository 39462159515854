// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeBlank_wrapper__evj8z {\n  max-width: 600px;\n  margin: 2em auto;\n}\n\n.HomeBlank_title__R5iC2 {\n  font-size: 28px;\n  font-weight: bold;\n}\n\n.HomeBlank_description__8wDza {\n  font-size: 18px;\n  margin: 2em 0;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/Home/HomeBlank/HomeBlank.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;AACJ;;AACA;EACI,eAAA;EACA,iBAAA;AAEJ;;AACA;EACI,eAAA;EACA,aAAA;EACA,kBAAA;AAEJ","sourcesContent":[".wrapper{\n    max-width: 600px;\n    margin: 2em auto;\n}\n.title{\n    font-size: 28px;\n    font-weight: bold;\n}\n\n.description{\n    font-size: 18px;\n    margin: 2em 0;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "HomeBlank_wrapper__evj8z",
	"title": "HomeBlank_title__R5iC2",
	"description": "HomeBlank_description__8wDza"
};
export default ___CSS_LOADER_EXPORT___;
