// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Schedules_summary__asLWM {\n  font-size: 16px;\n  padding-bottom: 2em;\n}\n\n.Schedules_row__wFVrC {\n  padding: 10px 20px;\n  display: flex;\n}\n\n.Schedules_day__4GO9d {\n  width: 20px;\n  display: flex;\n  align-items: end;\n  justify-content: start;\n}", "",{"version":3,"sources":["webpack://./src/components/Instances/Instance/Schedules/Schedules.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,aAAA;AACJ;;AACA;EACI,WAAA;EACA,aAAA;EACA,gBAAA;EACA,sBAAA;AAEJ","sourcesContent":[".summary{\n    font-size: 16px;\n    padding-bottom: 2em;\n}\n\n.row{\n    padding: 10px 20px;\n    display: flex;\n}\n.day{\n    width: 20px;\n    display: flex;\n    align-items: end;\n    justify-content: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary": "Schedules_summary__asLWM",
	"row": "Schedules_row__wFVrC",
	"day": "Schedules_day__4GO9d"
};
export default ___CSS_LOADER_EXPORT___;
