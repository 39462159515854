import React, {MouseEvent} from 'react';

import {generatePath, useNavigate} from 'react-router-dom';

import IAccess from '../../models/Access/IAccess';

import CustomImage from '../UI/Image/CustomImage';

import {AppUrls} from '../../constants/urls';

import styles from './Cloude.module.scss';

interface IAccessRowProps {
    item: IAccess
}

const CloudRow: React.FC<IAccessRowProps> = ({item}) => {
    const navigate = useNavigate();
    const onTrClick = (event: MouseEvent<HTMLTableRowElement>) => {
        event.preventDefault();
        navigate(generatePath(AppUrls.CLOUD, { cloudId: item.id }));
    };

    return (
        <tr className="clickable" onClick={onTrClick}>
            <td>
                <div className="d-flex align-items-center">
                    <div className={styles.image_wrapper} title={item.service.label}>
                        <CustomImage
                            src={item.service.icon}
                            width={20}
                            height={20}
                            alt={item.service.label}
                            title={item.service.label}
                        />
                    </div>
                    {item.service.label}
                </div>
            </td>
            <td>{item.label}</td>
            <td>{item.managed_instances} / {item.total_instances}</td>
        </tr>
    );
};

export default CloudRow;