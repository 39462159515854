import React from 'react';
import {PieChart} from 'react-minimal-pie-chart';


interface IEconomyChartProps {
    off: number,
}

const EconomyChart: React.FC<IEconomyChartProps> = ({off}) => {
    const data = [
        { title: 'Включено', value: 100-off, color: '#1a9f10'},
        { title: 'Выключено', value: off, color: '#c7c6c6'},
    ];
    return (
        <PieChart
            data={data}
            startAngle={180}
            style={{ height: '30px', width: '30px' }}
        />
    );
};

export default EconomyChart;