import React from 'react';

import {useParams} from 'react-router';

import NotFound from './NotFound';
import {instanceAPI} from '../api/instanceAPI';
import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';
import InstanceAnon from '../components/Instances/Instance/InstanceAnon/InstanceAnon';

const InstanceAnonPage: React.FC = () => {

    const {uuid} = useParams();

    if (!uuid) {
        return <NotFound/>;
    }

    const {data: item, isLoading} = instanceAPI.useGetInstanceAnonQuery(uuid);
    return (
        <BlankLayout pageTitle="Управление ВМ" isLoading={isLoading}>
            {
                item && <InstanceAnon anonItem={item} />
            }
        </BlankLayout>
    );
};

export default InstanceAnonPage;