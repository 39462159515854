// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainMenu_item_icon__pbiEy {\n  margin-right: 6px;\n  width: 1em !important;\n}\n\n.MainMenu_active_link__wFSUg {\n  background-color: #293846;\n  color: white !important;\n}\n\n.MainMenu_nav-logo__7W\\+G8 {\n  display: flex;\n  align-items: center;\n  font-size: 18px;\n  color: white;\n  font-weight: 600;\n}\n\n.MainMenu_nav-logo__7W\\+G8 span {\n  margin-left: 1rem;\n}\n\n.MainMenu_nav-bottom-item__Kn15u {\n  position: absolute !important;\n  bottom: 0;\n  width: 50% !important;\n  margin-left: 25px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/MainMenu/MainMenu.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,6BAAA;EACA,SAAA;EACA,qBAAA;EACA,iBAAA;AACJ","sourcesContent":[".item_icon {\n    margin-right: 6px;\n    width: 1em !important;\n}\n\n.active_link {\n    background-color: #293846;\n    color: white !important;\n}\n\n.nav-logo {\n    display: flex;\n    align-items: center;\n    font-size: 18px;\n    color: white;\n    font-weight: 600;\n}\n\n.nav-logo span {\n    margin-left: 1rem;\n}\n\n.nav-bottom-item {\n    position: absolute !important;\n    bottom: 0;\n    width: 50% !important;\n    margin-left: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item_icon": "MainMenu_item_icon__pbiEy",
	"active_link": "MainMenu_active_link__wFSUg",
	"nav-logo": "MainMenu_nav-logo__7W+G8",
	"nav-bottom-item": "MainMenu_nav-bottom-item__Kn15u"
};
export default ___CSS_LOADER_EXPORT___;
