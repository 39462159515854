import {createApi} from '@reduxjs/toolkit/query/react';

import {ThunkDispatch} from 'redux-thunk';

import {AnyAction} from 'redux';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';
import {buildQuery} from '../utils/query';
import IAccess from '../models/Access/IAccess';
import {providesList} from '../rtk-query/RTKQueryHelper';
import IInputAccess from '../models/Access/IInputAccess';

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
function updateListCache<QueryArg, P>(
    dispatch: ThunkDispatch<any, any, AnyAction>,
    id: number | undefined,
    patch: Pick<QueryArg, keyof QueryArg extends 'id' ? never : keyof QueryArg>
) {
    return dispatch(
        accessAPI.util.updateQueryData('getAccesses', undefined, (draftAccesses) => {
            const draft = draftAccesses.find(access => access.id === id);
            if (draft) {
                Object.assign(draft, patch);
            }
        }),
    );
}

export const accessAPI = createApi({
    reducerPath: 'accessAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['Access'],
    refetchOnMountOrArgChange: 60,
    endpoints: (build) => ({
        addAccess: build.mutation<IAccess, IInputAccess>({
            query: (data) => ({
                path: ApiUrls.ACCESSES,
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: 'Access', id: 'LIST' }],
        }),
        getAccesses: build.query<IAccess[], void>({
            query: () => ({
                path: buildQuery(ApiUrls.ACCESSES),
            }),
            providesTags: (result) => providesList(result, 'Access'),
        }),
        getAccess: build.query<IAccess, number>({
            query: (id) => ({
                path: ApiUrls.ACCESS(id),
            }),
            providesTags: (result, error, id) => [{ type: 'Access', id }],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedAccess } = await queryFulfilled;
                    updateListCache(dispatch, id, updatedAccess);
                } catch {}
            },
        }),
        updateAccess: build.mutation<IAccess, IInputAccess>({
            query: ({id, ...data}) => ({
                path: ApiUrls.ACCESS(id ?? 0),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    accessAPI.util.updateQueryData('getAccess', Number(id), (draft) => {
                        Object.assign(draft, patch);
                    })
                );
                try {
                    await queryFulfilled;
                    updateListCache(dispatch, id, patch);
                } catch {
                    patchResult.undo();
                }
            },
        }),
        deleteAccess: build.mutation<void, number>({
            query: (id) => ({
                path: ApiUrls.ACCESS(id),
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Access', id: 'LIST' }],
        }),
    }),
});