import React from 'react';

import cn from 'classnames';

import styles from './TimeRanges.module.scss';

interface ITimeRangeProps {
    width: string,
    left: string,
    color: string,
    timeFrom: string,
    timeTo: string,
}

const TimeRange: React.FC<ITimeRangeProps> = ({
    width,
    left,
    color,
    timeFrom,
    timeTo,
}) => {
    return (
        <div
            className={styles.range}
            style={{
                backgroundColor: color,
                width,
                left,
            }}
        >
            <div className={styles.label_from}>
                {timeFrom}
            </div>
            <div className={cn([
                styles.label_to,
                styles.down,
            ])}>
                {timeTo}
            </div>
        </div>
    );
};

export default TimeRange;