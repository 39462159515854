import React from 'react';

import {useSelector} from 'react-redux';

import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';

import {authLoadingSelector} from '../redux/authSlice';
import Login from '../components/Auth/Login';


const LoginPage: React.FC = () => {
    const isLoading = useSelector(authLoadingSelector);

    return (
        <BlankLayout pageTitle="Авторизация" isLoading={isLoading}>
            <Login/>
        </BlankLayout>
    );
};

export default LoginPage;