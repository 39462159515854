// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox_checkbox_label__eny\\+B {\n  display: inline-flex;\n  cursor: pointer;\n  margin-right: 10px;\n  margin-bottom: 0;\n}\n\n.Checkbox_checkbox_text__Z-ebR {\n  margin-left: -20px;\n  margin-top: -2px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;AACJ","sourcesContent":[".checkbox_label {\n    display: inline-flex;\n    cursor: pointer;\n    margin-right: 10px;\n    margin-bottom: 0;\n}\n\n.checkbox_text {\n    margin-left: -20px;\n    margin-top: -2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox_label": "Checkbox_checkbox_label__eny+B",
	"checkbox_text": "Checkbox_checkbox_text__Z-ebR"
};
export default ___CSS_LOADER_EXPORT___;
