// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SummaryItem_service_title__g7y4f {\n  font-size: 24px;\n  font-weight: bold;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.SummaryItem_image_wrapper__5Lu7O {\n  margin-right: 1rem;\n}\n@media (max-width: 768px) {\n  .SummaryItem_image_wrapper__5Lu7O {\n    display: none;\n  }\n}\n\n.SummaryItem_summary_wrapper__kAwPe {\n  margin: 0 2em;\n}\n\n.SummaryItem_summary_table__oa1j6 td {\n  text-align: left;\n  padding: 5px;\n  font-size: 16px;\n}", "",{"version":3,"sources":["webpack://./src/components/Home/SummaryItem/SummaryItem.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;AAAI;EAFJ;IAGO,aAAA;EAGL;AACF;;AAAA;EACI,aAAA;AAGJ;;AACI;EACI,gBAAA;EACA,YAAA;EACA,eAAA;AAER","sourcesContent":[".service_title{\n    font-size: 24px;\n    font-weight: bold;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.image_wrapper {\n    margin-right: 1rem;\n    @media (max-width: 768px) {\n       display: none;\n    }\n}\n\n.summary_wrapper{\n    margin: 0 2em;\n}\n\n.summary_table{\n    td{\n        text-align: left;\n        padding: 5px;\n        font-size: 16px;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"service_title": "SummaryItem_service_title__g7y4f",
	"image_wrapper": "SummaryItem_image_wrapper__5Lu7O",
	"summary_wrapper": "SummaryItem_summary_wrapper__kAwPe",
	"summary_table": "SummaryItem_summary_table__oa1j6"
};
export default ___CSS_LOADER_EXPORT___;
