import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import ICreateAccessForm from '../../../models/forms/CreateAccessForm';
import Input from '../../UI/Input/Input';
import Alert from '../../UI/Alert/Alert';
import FormGroup from '../../UI/FormGroup/FormGroup';
import TextArea from '../../UI/TextArea/TextArea';
import PrimaryButton from '../../UI/Button/PrimaryButton';

import {accessAPI} from '../../../api/accessAPI';

import WithSpinner from '../../../hoc/withSpinner';

import {DEFAULT_SERVICE_IRI} from '../../../constants/common';

import styles from './CreateAccessFrom.module.scss';

interface ICreateAccessFromProps{
    onSuccess: () => void
}

const CreateAccessFrom: React.FC<ICreateAccessFromProps> = ({onSuccess}) => {
    const [addAccess, {isLoading}] = accessAPI.useAddAccessMutation();
    const {register, handleSubmit, formState} = useForm<ICreateAccessForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    const onSubmit: SubmitHandler<ICreateAccessForm> = async(formData) => {
        await addAccess({
            service: DEFAULT_SERVICE_IRI,
            settings: formData,
        }).unwrap();

        onSuccess();
    };

    return (
        <WithSpinner isSpinnerVisible={isLoading}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>

                <FormGroup
                    hasError={errors.service_account_id !== undefined}
                >
                    <Input
                        type="text"
                        name="service_account_id"
                        placeholder="ID сервисного аккаунта"
                        register={register}
                        required
                    />
                    {errors.service_account_id && <Alert>{errors.service_account_id.message}</Alert>}
                </FormGroup>

                <FormGroup
                    hasError={errors.key_id !== undefined}
                >
                    <Input
                        type="text"
                        name="key_id"
                        placeholder="ID ключа"
                        register={register}
                        required
                    />
                    {errors.key_id && <Alert>{errors.key_id.message}</Alert>}
                </FormGroup>

                <FormGroup
                    hasError={errors.private_key !== undefined}
                >
                    <TextArea
                        name="private_key"
                        placeholder="Приватный ключ"
                        register={register}
                        required
                    />
                    {errors.private_key && <Alert>{errors.private_key.message}</Alert>}
                </FormGroup>

                <FormGroup>
                    <PrimaryButton
                        type="submit"
                        className="full-width"
                        disabled={!isDirty || !isValid}
                    >
                    Подключиться к Яндекс.Облаку
                    </PrimaryButton>
                </FormGroup>
            </form>
        </WithSpinner>
    );
};

export default CreateAccessFrom;