import React, {ComponentProps} from 'react';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import cn from 'classnames';

import {SizeProp} from '@fortawesome/fontawesome-svg-core';

import styles from './IconButton.module.scss';

export interface IIconButtonProps extends ComponentProps<'button'>{
    icon: IconDefinition,
    title: string,
    text?: string,
    size?: SizeProp
}

const IconButton: React.FC<IIconButtonProps> = ({icon, title, text, size, className, ...rest}) => {

    return (
        <button
            className={cn([styles['icon_button'], className])}
            {...rest}
        >
            <FontAwesomeIcon icon={icon} size={size ?? '2x'} color="grey" title={title}/>
            {
                text && <span className={styles['text']}>{text}</span>
            }
        </button>
    );
};

export default IconButton;