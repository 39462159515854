import React, {MouseEvent, ReactNode, useCallback} from 'react';

import {generatePath, useNavigate} from 'react-router-dom';

import {faPencil, faPlay, faRefresh, faStop, faTrash} from '@fortawesome/free-solid-svg-icons';

import {toast} from 'react-toastify';

import IInstance from '../../models/Instance/IInstance';
import MoreActionsItem from '../UI/MoreActions/MoreActionsItem';
import MoreActionsDots from '../UI/MoreActions/MoreActionsDots';
import {AppUrls} from '../../constants/urls';
import {NormalizedStatus} from '../../models/Instance/NormalizedStatus';
import IAccess from '../../models/Access/IAccess';
import {instanceAPI} from '../../api/instanceAPI';
import {ManagementStatus} from '../../models/Instance/ManagementStatus';

interface IInstanceMenuProps {
    item: IInstance,
    access: IAccess,
}

const InstanceMenu: React.FC<IInstanceMenuProps> = ({item, access}) => {
    const navigate = useNavigate();
    const [changeStatus] = instanceAPI.useChangeInstanceStatusMutation();
    const [updateInstance] = instanceAPI.useUpdateInstanceMutation();
    const onClickOn = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        await changeStatus({
            id: item.id,
            targetStatus: 'on',
            accessId: access.id,
        }).unwrap();
        toast.info('Отправлен запрос на включение ВМ');
    }, [item.id, access.id]);

    const onClickOff = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        await changeStatus({
            id: item.id,
            targetStatus: 'off',
            accessId: access.id,
        }).unwrap();

        toast.info('Отправлен запрос на выключение ВМ');
    }, [item.id, access.id]);

    const onClickRestart = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        await changeStatus({
            id: item.id,
            targetStatus: 'restart',
            accessId: access.id,
        }).unwrap();

        toast.info('Отправлен запрос на перезапуск ВМ');
    }, [item.id, access.id]);

    const onClickForget = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();

        await updateInstance({
            id: item.id,
            management_status: ManagementStatus.Deleted,
        }).unwrap();

        toast.info('Машина забыта');
    }, []);

    const onClickEdit = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        navigate(generatePath(AppUrls.INSTANCE, {instanceId: item.id, cloudId: access.id}));
    }, [item.id]);

    const getActions = (): ReactNode[] => {
        const actions: ReactNode[] = [];
        if (item.management_status === ManagementStatus.Disappeared) {
            actions.push(
                <MoreActionsItem
                    key="trash"
                    label="Забыть"
                    icon={faTrash}
                    onClick={onClickForget}
                />
            );
        } else if (item.normalized_status === NormalizedStatus.On) {
            actions.push(
                <MoreActionsItem
                    key="off"
                    label="Выключить"
                    icon={faStop}
                    onClick={onClickOff}
                />
            );
            actions.push(
                <MoreActionsItem
                    key="restart"
                    label="Перезапустить"
                    icon={faRefresh}
                    onClick={onClickRestart}
                />
            );
        } else if (item.normalized_status === NormalizedStatus.Off) {
            actions.push(
                <MoreActionsItem
                    key="on"
                    label="Включить"
                    icon={faPlay}
                    onClick={onClickOn}
                />
            );
        }

        actions.push(
            <MoreActionsItem
                key="edit"
                label="Редактировать"
                icon={faPencil}
                onClick={onClickEdit}/>
        );
        return actions;
    };

    return (
        <MoreActionsDots>
            {
                getActions().map(el => el)
            }
        </MoreActionsDots>
    );
};

export default React.memo(InstanceMenu);