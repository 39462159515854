import React from 'react';

import {useSearchParams} from 'react-router-dom';

import Box from '../../UI/Box/Box';
import {AppUrls} from '../../../constants/urls';
import LinkButton from '../../UI/Button/LinkButton';

import styles from '../Auth.module.scss';

const PasswordResetSent: React.FC = () => {
    const [query] = useSearchParams();
    const email = query.get('email') ?? '';
    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <Box>
                    <div className="row text-center mb-3">
                        <div className="col-12">
                            <h2>Восстановление доступа</h2>
                            <p>На указанный вами адрес {email} отправлено письмо,
                                содержащее ссылку для сброса пароля.</p>
                        </div>
                    </div>
                    <LinkButton
                        href={AppUrls.LOGIN}
                        className="btn btn-primary full-width mb-2 mb-sm-0"
                    >Продолжить</LinkButton>
                </Box>
            </div>
        </div>
    );
};

export default PasswordResetSent;