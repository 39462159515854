// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccessLinks_wrapper__oLUpM {\n  margin: 2em auto;\n}\n\n.AccessLinks_header__loGiW {\n  font-size: 16px;\n  font-weight: bold;\n  margin-bottom: 1em;\n}\n\n.AccessLinks_description__goBDm {\n  font-size: 14px;\n  margin-bottom: 1em;\n}", "",{"version":3,"sources":["webpack://./src/components/Instances/Instance/AccessLinks/AccessLinks.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AACA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;;AACA;EACI,eAAA;EACA,kBAAA;AAEJ","sourcesContent":[".wrapper{\n    margin: 2em auto;\n}\n.header{\n    font-size: 16px;\n    font-weight: bold;\n    margin-bottom: 1em;\n}\n\n.description{\n    font-size: 14px;\n    margin-bottom: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AccessLinks_wrapper__oLUpM",
	"header": "AccessLinks_header__loGiW",
	"description": "AccessLinks_description__goBDm"
};
export default ___CSS_LOADER_EXPORT___;
