// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Alert_alert__NPrjL {\n  width: 100%;\n  padding: 4px 12px;\n  font-size: 11px;\n  background-color: rgba(237, 85, 101, 0.2117647059);\n  border: 1px solid rgba(237, 85, 101, 0.5215686275);\n  color: #212529;\n  border-radius: 1px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/Alert/Alert.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;EACA,eAAA;EACA,kDAAA;EACA,kDAAA;EACA,cAAA;EACA,kBAAA;AACJ","sourcesContent":[".alert {\n    width: 100%;\n    padding: 4px 12px;\n    font-size: 11px;\n    background-color: #ed556536;\n    border: 1px solid #ed556585;\n    color: #212529;\n    border-radius: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "Alert_alert__NPrjL"
};
export default ___CSS_LOADER_EXPORT___;
