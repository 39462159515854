import React from 'react';
import cn from 'classnames';


export interface BoxProps  {
    children: React.ReactNode;
    className?: string;
}

const Box: React.FC<BoxProps> = (props) => {
    return <div className={cn('ibox-content', [props.className])}>
        {props.children}
    </div>;
};

export default Box;