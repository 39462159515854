import {EventSourcePolyfill} from 'event-source-polyfill';

import {iri} from './stringHelper';
import {fetchMercureTokenAnon, getMercureToken, refreshTokens} from './auth';
import {isDev} from './evironment';

export class MercureService{

    private _anonUuid?: string;
    set anonUuid(value: string) {
        this._anonUuid = value;
    }

    private static instance: MercureService;
    private connection?: EventSourcePolyfill | null;
    private onMessageListener?: ()=>void;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {

    }

    public static getInstance(): MercureService{
        if (!this.instance) {
            this.instance = new MercureService();
        }

        return this.instance;
    }

    public startConnect(): void {
        if (this.isActive()) {
            return;
        }
        const url = '/.well-known/mercure?topic=' + encodeURIComponent(iri('instances', '{id}'));

        this.connection = new EventSourcePolyfill(url, {
            headers: {
                Authorization: 'Bearer ' + getMercureToken(),
                'Content-Type': 'text/event-stream',
                'Cache-Control': 'no-cache',
                Connection: 'keep-alive',
                'X-Accel-Buffering': 'no',
            },
            heartbeatTimeout: 90000,
            withCredentials: true,
        });
        this.connection.onerror = (event:any) => {
            if (event?.status === 401) {
                this.reconnect().then(()=>{
                    // eslint-disable-next-line no-console
                    isDev() && console.log('Mercure reconnected');
                });
            }
        };
    }

    // eslint-disable-next-line no-unused-vars
    public setOnMessageListener(callback: (...args: any[]) => void){
        if (this.connection) {
            this.onMessageListener = callback;
            this.connection.onmessage = callback;
        }
    }

    private async reconnect() {
        this.connection?.close();
        this.connection = null;
        if (this._anonUuid && this._anonUuid !== '') {
            await fetchMercureTokenAnon(this._anonUuid);
        }else{
            await refreshTokens();
        }

        this.startConnect();

        if (this.onMessageListener) {
            this.setOnMessageListener(this.onMessageListener);
        }
    }

    private isActive(): boolean{
        return !!this.connection;
    }
}