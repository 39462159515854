import React from 'react';

import {useNavigate} from 'react-router-dom';

import CreateAccessFrom from '../../Clouds/CreateAccessForm/CreateAccessFrom';

import Box from '../../UI/Box/Box';

import {AppUrls} from '../../../constants/urls';

import styles from './HomeBlank.module.scss';

const HomeBlank: React.FC = () => {
    const navigate = useNavigate();
    
    const onSuccess = () => {
        navigate(AppUrls.CLOUDS);
    };

    return (
        <Box className={styles.wrapper}>
            <div className="d-flex flex-column align-items-center">
                <div className={styles.title}>Обозревать пока нечего :-)</div>
                
                <p className={styles.description}>
                    Для контроля за состоянием ваших облачных серверов сервису Overseer
                    нужен доступ к API Яндекс.Облака с
                    правами
                    <strong> resource-manager.clouds.member</strong>,
                    <strong> compute.operator</strong> и <strong>iam.serviceAccounts.user</strong>
                </p>

                <CreateAccessFrom onSuccess={onSuccess}/>
            </div>
        </Box>

    );
};

export default HomeBlank;