// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Auth_wrapper__oBPRq {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.Auth_box_wrapper__lbevf {\n  width: 100%;\n  max-width: 400px;\n  margin: 0 auto;\n}\n\n.Auth_new_account_wrapper__WFWWd {\n  text-align: center;\n  margin: 0;\n}\n\n.Auth_codeword__NlwCV {\n  width: 100%;\n  max-width: 400px;\n  margin: 0 auto;\n}\n\n.Auth_confirm_email__msg__Ob6Db,\n.Auth_reset_password__msg__Qlgyd {\n  background: rgba(26, 179, 148, 0.2784313725);\n}\n\n.Auth_confirm_email__msg_error__gMJQ6,\n.Auth_reset_password__msg_error__tYciN {\n  background: rgba(241, 8, 8, 0.3215686275);\n}", "",{"version":3,"sources":["webpack://./src/components/Auth/Auth.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAEA;;EAEE,4CAAA;AACF;;AAEA;;EAEE,yCAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.box_wrapper {\n  width: 100%;\n  max-width: 400px;\n  margin: 0 auto;\n}\n\n.new_account_wrapper {\n  text-align: center;\n  margin: 0;\n}\n\n.codeword {\n  width: 100%;\n  max-width: 400px;\n  margin: 0 auto;\n}\n\n.confirm_email__msg,\n.reset_password__msg {\n  background: #1ab39447;\n}\n\n.confirm_email__msg_error,\n.reset_password__msg_error {\n  background: #f1080852;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Auth_wrapper__oBPRq",
	"box_wrapper": "Auth_box_wrapper__lbevf",
	"new_account_wrapper": "Auth_new_account_wrapper__WFWWd",
	"codeword": "Auth_codeword__NlwCV",
	"confirm_email__msg": "Auth_confirm_email__msg__Ob6Db",
	"reset_password__msg": "Auth_reset_password__msg__Qlgyd",
	"confirm_email__msg_error": "Auth_confirm_email__msg_error__gMJQ6",
	"reset_password__msg_error": "Auth_reset_password__msg_error__tYciN"
};
export default ___CSS_LOADER_EXPORT___;
