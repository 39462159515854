import React from 'react';
import ReactDOM from 'react-dom/client';

import {Provider} from 'react-redux';
import {DevSupport} from '@react-buddy/ide-toolbox';

import {BrowserRouter as Router} from 'react-router-dom';

import {makeStore} from './store';
import 'react-toastify/dist/ReactToastify.css';

import './styles/globals.scss';

import App from './App';

import {ComponentPreviews, useInitial} from './dev';

const store = makeStore();

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
                >
                    <App/>
                </DevSupport>
            </Router>
        </Provider>
    </React.StrictMode>,
);