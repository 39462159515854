import React, {useState} from 'react';

import PrimaryButton from '../UI/Button/PrimaryButton';
import CreateAccessModal from './CreateAccessModal';

const CreateAccessButton: React.FC = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <PrimaryButton
                onClick={() => setIsModalOpen(true)}
            >
                Добавить площадку
            </PrimaryButton>
            <CreateAccessModal open={isModalOpen} handleClose={handleClose}/>
        </>
    );
};

export default CreateAccessButton;