import {createApi} from '@reduxjs/toolkit/query/react';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';
import IAccessLink from '../models/AccessLink/IAccessLink';
import {instanceAPI} from './instanceAPI';
import {iri} from '../utils/stringHelper';
import IInputAccessLink from '../models/AccessLink/IInputAccessLink';
import IInstanceChangeStatusAnonInput from '../models/Instance/IInstanceChangeStatusAnonInput';

async function clearInstanceCache(queryFulfilled: any, dispatch: any, instanceId: number) {
    await queryFulfilled;
    dispatch(
        instanceAPI.util.invalidateTags([{type: 'Instance', id: instanceId}]),
    );
}

export const accessLinkAPI = createApi({
    reducerPath: 'accessLinkAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['AccessLink'],
    refetchOnMountOrArgChange: 60,
    endpoints: (build) => ({
        addAccessLink: build.mutation<IAccessLink, IInputAccessLink>({
            query: ({instanceId, accessId, email}) => ({
                path: ApiUrls.ACCESS_LINKS,
                method: 'POST',
                data: {
                    instance: iri('instances', instanceId),
                    access: iri('accesses', accessId),
                    email,
                },
            }),
            async onQueryStarted({ instanceId }, { dispatch, queryFulfilled }) {
                await clearInstanceCache(queryFulfilled, dispatch, instanceId);
            },
        }),
        deleteAccessLink: build.mutation<void, {uuid: string, instanceId: number}>({
            query: ({uuid}) => ({
                path: ApiUrls.ACCESS_LINK(uuid),
                method: 'DELETE',
            }),
            async onQueryStarted({ instanceId }, { dispatch, queryFulfilled }) {
                await clearInstanceCache(queryFulfilled, dispatch, instanceId);
            },
        }),
        changeInstanceStatusAnon: build.mutation<void, IInstanceChangeStatusAnonInput>({
            query: ({uuid, targetStatus}) => ({
                path: ApiUrls.INSTANCE_ANON(uuid),
                method: 'POST',
                data: {
                    target_status: targetStatus,
                },
                isAnonymous: true,
            }),
        }),
    }),
});