import React from 'react';
import Modal from 'rsuite/Modal';

import CreateAccessLinkForm from './CreateAccessLinkForm/CreateAccessLinkForm';

interface ICreateAccessLinkModalProps {
    instanceId: number,
    accessId: number,
    open: boolean,
    handleClose: () => void;
}

const CreateAccessLinkModal: React.FC<ICreateAccessLinkModalProps> = ({
    instanceId,
    accessId,
    open,
    handleClose,
}) => {
    return (
        <Modal
            backdrop="static"
            keyboard={false}
            open={open}
            onClose={handleClose}
            size="md"
        >
            <Modal.Header>
                <Modal.Title>Предоставить доступ</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <CreateAccessLinkForm
                    instanceId={instanceId}
                    accessId={accessId}
                    onSuccess={handleClose}
                />
            </Modal.Body>
        </Modal>
    );
};

export default CreateAccessLinkModal;